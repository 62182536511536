import { Platform, Dimensions } from 'react-native';
import { Color } from '../utils/color';
import { createStyles, maxWidth } from 'react-native-media-queries'

const base = {
  profileContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  columnContainer: {
    flex: 1,
    backgroundColor: Color.White,
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    backgroundColor: Color.White,
    flexDirection: 'row',
    margin: 5,
    height: Dimensions.get("window").height
  },
  welcomeImage: {
    width: 80,
    height: 64,
    resizeMode: 'contain',
  },
  logo: {
    flex: 1,
    backgroundColor: Color.White,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    alignItems: 'flex-start',
  },
  title: {
    //flex: 3,
    fontSize: 30,
    color: Color.DarkGreen,
    // alignItems: 'center',
    // paddingTop: 15,
  },
  personal: {
    fontSize: 20,
    color: Color.DarkGreen,
    alignItems: 'center',
    paddingTop: 8,
  },
  menu: {
    flex: 1,
    paddingTop: 15,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#005961',
    borderRadius: 5,
    margin: 10,
    height: 65,
    width: 150,
  },
  ProfileBehaviorbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#005961',
    borderRadius: 5,
    margin: 5,
    height: 30,
    width: 60,
  },
  expand: {
    margin: 10,
    width: 10,
    height: 30
  },
  buttonText: { color: Color.White, },
  buttonPlusText: { color: Color.DarkGreen },
  smallButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#005961',
    borderRadius: 5,
    height: 25,
    width: 90,
  },
  menuItem: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomColor: Color.GreenLineColor
  },
  submenuItem: {
    padding: 20,
    paddingLeft: 50,
    alignContent:'flex-start', 
    alignItems:'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: Color.GreenLineColor
  },
  menuText: {
    color: Color.White,
    fontSize: 14,
    fontWeight: 'bold'
  },
  submenuText: {
    color: Color.White,
    fontSize: 12
  },
  notificationListItem: {
    width: '99%',
    backgroundColor: Color.White,
    margin: 10,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    elevation: 1,
    paddingLeft: '1.5%',
    paddingRight: '1.5%',
    paddingBottom: '1%',
    paddingTop: '1%',
    position: "relative",
  },
  SocialMediaNotification: {
    flexDirection: 'row',
    alignItems: "center",
    paddingLeft: '8%',
    marginBottom: 12,
    justifyContent: 'space-between',
  },
  line: {
    width: 1,
    height: 11,
    marginLeft: 20,
    marginRight: 15,
    backgroundColor: Color.BorderLine,
  },
  dotted: {
    width: 9,
    height: 9,
    borderRadius: 5,
  },
  notificationText: {
    paddingLeft: '5%',
  },
};
export const BaseStyle = createStyles(
  base,
  maxWidth(991, {
    logo: {
      flex: 1,
      paddingTop: (Platform.OS == 'ios' ? 20 : 40),
      paddingBottom: 20,
      paddingLeft: 10,
    }
  })
)