import React, { useState, useEffect, useContext } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { TouchableOpacity, Dimensions, SafeAreaView, Text, View} from 'react-native';
import { ButtonGroup, Card, Image, Button } from 'react-native-elements';
import { Color } from '../utils/color';
import { EvilIcons, Foundation } from '@expo/vector-icons';
import AuthenticatedLayout from './AuthenticatedLayout';
import { postInfoRequestAcceptance, fetchInformationRequests, fetchRewardsStatus, postRedemptionRequest, fetchRewardsCatalog } from '../services/RewardService';
import config from '../config';
import { UserContext } from '../UserContext';
import { showConfirm, showMessage } from '../utils/UIUtil';
import { InformationRequestDetails } from '../components/InformationRequestDetails';
import * as DocumentPicker from 'expo-document-picker';
import UploadFile from '../components/UploadFile';

export default function UploadFileComponent(props) {
    const userData = useContext(UserContext);
    return (
        <AuthenticatedLayout {...props} title="UploadFile">
            {originalWidth => (<UploadFile {...userData.userData} originalWidth={originalWidth} />)}
        </AuthenticatedLayout>
    );
}

