import React, { useState, useEffect, useContext } from 'react';
import config from '../config';
import * as DocumentPicker from 'expo-document-picker';
import { View, Button } from 'react-native';

const UploadFile = (props) => {
    const [data, setData] = useState(undefined);

    const submitData = async () => {
      if(!data) {
        alert('Please select the file to upload');
        return false;
      }

      try {
          props.setInprogress(true);
          const form = new FormData();
          console.log(data);
          form.append('file_attatchment', data.file);
          let res = await fetch(
          config.apiurl + '/data-dump/' + props.selected,
          {
            method: 'POST',
            body: form,
            headers: {
              'Authorization': 'Bearer ' + props.accessToken
            }
          }
        );
        let responseJson = await res.json();
        if (responseJson.message) {
          alert('Successfully loaded');
          props.setShowInstruction(false);
          props.setInprogress(false);
        }
        else if(responseJson.code == 'Error') {
          alert(responseJson.message);
        }
        else {
          console.log(responseJson);
        }
      } catch(error) {
        alert('Problem in file upload', error);
      }
    };

    const _pickDocument = async () => {
        const result = await DocumentPicker.getDocumentAsync({});
        if(result.file.type.indexOf('zip') === -1) {
          alert('Please upload only zip file');
          return; 
        }
        setData(result);
    };

    console.log(data);
    return (
      <View style={{ paddingLeft: 5, marginTop: 10, flexDirection:'row'}}>
        <View style={{margin:10}}>
        <Button
          title={` ${data ? `Select Zip Document ${data.name}` : `Select Zip Document`}`}
          color='#185b61'
          onPress={() => _pickDocument()}
      />
      </View>
      <View style={{margin:10}}>
      <Button
          title="Submit"
          color='#185b61'
          onPress={() => submitData()}
      />
      </View>
      </View>
    )
}

export default UploadFile;