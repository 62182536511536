import AsyncStorage from '@react-native-async-storage/async-storage'
export const fetchProduct = async (limit, start, search_key, search_value) => {

    let latitude = JSON.parse(await AsyncStorage.getItem('latitude'));
    let longitude =JSON.parse(await AsyncStorage.getItem('longitude'));

    let response = await fetch(`https://commerce.cxsphere.com/individual/fetch-all-products?limit=${limit}&start=${start}&latitude=${latitude}&longitude=${longitude}&distance_unit=mi&search_key=${search_key}&search_value=${search_value}`, {
     method: 'GET',
     headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      }
    });

    let responseJson = await response.json();
    let header = [
      '',
      'Store Name',
      'Address',
      'Contact',
      'Email',
      'Name',
      'Price Range',
      'Distance',
      '',
      ''
    ]
   
    let content = [];
    let { products} = responseJson
    for(let i=0;i<products.length;i++) {
      let data = {};
      data.logo = products[i].store_details.store_logo;
      data.store_name = products[i].store_details.store_name;
      data.address = products[i].store_details.store_address;
      data.contact = products[i].store_details.store_contact;
      data.email = products[i].store_details.store_email;
      data.store_latitude = products[i].store_details.store_latitude || 13.08;
      data.store_longitude = products[i].store_details.store_latitude || 80.27;
      //data.category = products[i].category;
      //data.sub_category = products[i].sub_category;
      data.type = products[i].attribute_data.type; 
      data.id =  products[i].cxs_product_id;
      data.store_id = products[i].store_details.store_id;
      data.name = products[i].product_name;
      data.url = products[i].attribute_data.url ? products[i].attribute_data.url : null;
      data.price_range = products[i].attribute_data.price_range ? 
      products[i].attribute_data.currency + ' ' + products[i].attribute_data.price_range : null;
      data.distance = products[i].distance;
      data.lat = latitude;
      data.long = longitude;
      content.push(data)
    }
    
    return  { 
      count : responseJson.count, 
      limit: responseJson.limit, 
      start: responseJson.start,
      header, 
      content,
      rawProducts: products
    };
}

export const addToCart = async(data, user_id, coupon_code) => {
  let content = { coupon_code, user_id , products: []}
  let total = 0;
  for(let i=0;i<data.length;i++) {
    let items = data[i].store.items;
    for(let j=0;j<items.length;j++) {
      total += items[j].price;
      content.products.push({
        'quantity': items[j].counter,
        'store_id': items[j].store_id,
        'product_id': items[j].id,
        'size': items[j].weight,
        'price': items[j].price,
        'discount': 10
     })
    }
  }

  content.total = total;
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(content)
  };
  
  let response = await fetch('https://commerce.cxsphere.com/individual/add-user-orders', requestOptions)
  return response   
}

export const fetchHistory = async (user_id) => {
  let response = await fetch(`https://commerce.cxsphere.com//individual/fetch-user-orders?user_id=${user_id}`, {
   method: 'GET',
  });
  return response.json()
}

