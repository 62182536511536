import React, {useState, useEffect, useContext } from 'react';
import { Text, SafeAreaView, View, ActivityIndicator, Platform, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import {processGoogleAuthorizationCode} from '../services/SocialSignInService';
import {BaseStyle} from '../stylesheets/BaseStyle';
import {UserContext} from '../UserContext';
import CXSphereBanner from '../components/CXSphereBanner';
import { Color } from '../utils/color';
import useNativeLayout from '../hooks/NativeLayoutHook';


export default function GoogleLoginCallbackScreen(props) {
    const [isRedirect, setIsRedirect] = useState(false);    
    console.log("props");
	console.log(props.navigation.state);
    const code = props.navigation.state.params.code;
    const params = props.navigation.state.params;
    let userToken = 0;
	console.log(params);
	let social_source = 'google';
	if ('state' in params) {
		social_source = 'facebook';
	}
    const userData = useContext(UserContext);
    if (Object.keys(userData.userData).length !== 0) {
        userToken = userData.userData.accessToken;
    }
    console.log('is existing user??');
    console.log(userToken);
    console.log("Code received: " + code);
    const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();
    useEffect(() => {
    const outerProcessData = async () => {
        if (!code) {
            console.log("Code is undefined or null/empty.");
            props.navigation.navigate("Login");
            return;
        }
        const res = await processGoogleAuthorizationCode(code, Platform.OS, social_source, userToken);
        if (res.status !== 'Error') {
                if (userToken === 0) {
                const user = {'accessToken': res.access_token, 'fullName': res.fullName, 
                'profilePhotoLink': res.profile, 
                user_id: res.user_id,
                email: res.email };
                AsyncStorage.setItem('user', JSON.stringify(user));
                userData.setUserData(user);
                console.log("Set userData done." + res.access_token);
            }
                
            let organization = await AsyncStorage.getItem('Organization');
            organization = organization ? JSON.parse(organization) : {};
            console.log('final organization', organization);
            if(organization.org_id) { 
                props.navigation.navigate("Organization");
            } else {
                setIsRedirect(true);
            }
        }      
    }    
    
    outerProcessData();
    },[]);

    console.log('testing props', props);
    return (
        <SafeAreaView onLayout={_handleLayout} style={{ flex: 1 }}>
            <View style={[BaseStyle.container, { backgroundColor: originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
            <ActivityIndicator size="large" animating={true} />
            <Text>Please wait...</Text>
            {isRedirect === true && props.navigation.navigate('Dashboard')}
            </View>
        </SafeAreaView>
    );
}

