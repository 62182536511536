import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator
} from 'react-native';
import useNativeLayout from '../hooks/NativeLayoutHook';
import config from '../config';
import Label from '../components/Label';
import TextBox from '../components/textbox';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { validateEmail } from '../utils/validation';
import { StdAlert } from './../components/Alert.web'
import styles from '../stylesheets/ForgotPasswordStyle';

export default function ForgotPassword(props) {

    const [emailID, setEmailID] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();

  const handleText = (name, setValue) => {
    return (text) => {
      setValue(text.replace(/\s/g, ''));
    }
  }

  const validation = () => {
    var message = "";
    if (!validateEmail(emailID)) {
      message = "Please enter valid emailid"
    }
    return message;

  }

  const forgotPasswordAPI = async () => {

    let messageAlert = validation();

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        StdAlert(alertTitle, alertText, () => {
          console.log('OK Pressed');
        })
      } else {

        Alert.alert(
          alertTitle,
          alertText,
          [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ],
          { cancelable: false },
        );
      }
      return;
    }
    setLoading(true);

    try {

      let response = await fetch(config.apiurl + '/forgot-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": emailID
        }),
      });


      setLoading(false);

      if (response.ok) {
        let responseJson = await response.json();

        console.log("====== responseJson =====", responseJson);

        if (responseJson && responseJson.code == "Success") {

          if (Platform.OS === 'web') {
            StdAlert('CXSphere', responseJson.message, () => {
              props.navigation.goBack()
            })
          } else {
            Alert.alert(
              'CXSphere',
              responseJson.message,
              [
                {
                  text: 'OK', onPress: () => {
                    props.navigation.goBack()
                  }
                },
              ],
              { cancelable: false },
            );
          }

        } else {
          if (Platform.OS === 'web') {
            alert(responseJson.message)
          } else {
            Alert.alert("CXSphere", responseJson.message)
          }
        }

      } else {
        alert("User is not found");
      }

    } catch (error) {
      setLoading(false);
    }

  }

    return (
      <SafeAreaView onLayout={_handleLayout} style={{ flex: 1 }}>
        <View style={[styles.Container, { backgroundColor: originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "center"
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <Label Barlow_SemiBold style={[styles.title, { display: (originalWidth < 991 ? 'none' : 'flex'), alignSelf: 'center', }]}>Forgot Password</Label>
          <View style={styles.boxRow}>
            <View style={[styles.login, { paddingTop: (originalWidth < 991 ? 20 : 0) }]}>
              <TextBox
                textStyle={{ backgroundColor: Color.White }}
                onChangeText={handleText("emailID", setEmailID)}
                onSubmitEditing={() => {
                  forgotPasswordAPI()
                }}
                placeholder="Email" />
              <TouchableOpacity onPress={forgotPasswordAPI} style={styles.button} >
                {!isLoading && <Label style={{ fontSize: 14 }} Barlow_Regular color={Color.White}>Send</Label>}
                {isLoading && <ActivityIndicator size="small" color="white" />}
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { props.navigation.goBack() }} style={styles.BackBtn} >
                <Image style={{ width: 14, height: 14, tintColor: Color.DarkGreen, marginRight: 2, }} source={require("../assets/images/back.png")} />
                <Label mt={(Platform.OS == 'android' ? -2 : 0)} font14Normal Barlow_Regular color={Color.DarkGreen}>Back to Login</Label>
              </TouchableOpacity>
            </View>
          </View>
        </View>

      </SafeAreaView >
    );
  }


ForgotPassword.navigationOptions = {
  header: null,
};
