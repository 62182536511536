import { messaging } from '../services/Firebase';

export default function InitializeCXSpherePushNotification(props) {
    if (!('serviceWorker' in navigator) && !('PushManager' in window)) {
        console.log("Push notification not supported!");
    } else {
        console.log("Congratulations, push notifications supported in your browser!");
    }
}
/**
 * Check if user has already given permission for sending notifications
 * If not, request permission from user, generate instance token and store it in firestore
 */

// import {registerCXSphereServiceWorker, askForPermissionToReceiveNotifications, initializeFirebase} from '../services/FirebaseSetup'
// export default function InitializeCXSpherePushNotification(props) {
//     console.log("Inside InitializeCXSpherePushNotification.");
//     initializeFirebase();
//     console.log("Initialized firebase, asking user for permission.");
//     askForPermissionToReceiveNotifications();
//     console.log("After permission prompt. Registering custom service worker.");
//     registerCXSphereServiceWorker();
// }