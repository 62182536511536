import React, { useState, useEffect, useContext } from 'react';
import {Platform, Dimensions, StyleSheet, View, Alert} from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppNavigator from './navigation/AppNavigator';
import { UserContext } from './UserContext';
import { Color } from './utils/color';
import { MenuProvider } from 'react-native-popup-menu';
import CXSphereAppLoading from './components/CXSphereAppLoading';
import InitializeCXSpherePushNotification from  './components/CXSpherePushNotification';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [userData, setUserData] = useState({});
  const [pushNotificationToken, setPushNotificationToken] = useState("");
  const [notification, setNotification] = useState({});

  const getLocationAsync = async () => {
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    if (status !== 'granted') {
      console.log('Permission to access location was denied')
    }

    let location = await Location.getCurrentPositionAsync({accuracy:Location.Accuracy.Highest});
    const { latitude , longitude } = location.coords
    AsyncStorage.setItem('latitude', JSON.stringify(latitude));
    AsyncStorage.setItem('longitude',JSON.stringify(longitude));
  };

  getLocationAsync();

  useEffect(() => {
      InitializeCXSpherePushNotification({'setPushNotificationToken': setPushNotificationToken, 'handleNotification': setNotification});
  }, []);
  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <CXSphereAppLoading {...props} setUserData={setUserData} setLoadingComplete={setLoadingComplete} />
    );
  } else {
    return (
      <UserContext.Provider value={{ 'userData': userData, 'setUserData': setUserData }}>
        <SafeAreaProvider style={[styles.container]}>
          <MenuProvider>
            <AppNavigator />
          </MenuProvider>
        </SafeAreaProvider>
      </UserContext.Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.White,
    flexDirection: 'row',
    height: Dimensions.get("window").height,
  },
  heading: {
    fontSize: 50,
    fontWeight: "bold",
    margin: "auto",
  }
});