import React from 'react';
import { Text } from 'react-native';
import { Color } from '../utils/color';
import { fontXSmall, fontSmall, fontNormal, fontLarge, fontXLarge, fontX12Small, fontX10Small } from '../utils/theme';
import PropTypes from 'prop-types';

class Label extends React.Component {

    onClick = () => {
        if (this.props.onPress)
            this.props.onPress();
    };

    render() {
        let stylesArray = [];
        if (this.props.font18Large)
            stylesArray.push({ fontSize: 20 });
        else if (this.props.font15Large)
            stylesArray.push({ fontSize: 16 });
        else if (this.props.font14Normal)
            stylesArray.push({ fontSize: 14 });
        else if (this.props.font11Small)
            stylesArray.push({ fontSize: 11 });
        else if (this.props.font9Small)
            stylesArray.push({ fontSize: 9 });
        else if (this.props.x10Small)
            stylesArray.push({ fontSize: 10 });
        else if (this.props.x12Small)
            stylesArray.push({ fontSize: 14 });

        else
            stylesArray.push({ fontSize: fontNormal });
        if (this.props.Barlow_Black)
            stylesArray.push({ fontFamily: "Barlow-Black" });
        else if (this.props.Barlow_Bold)
            stylesArray.push({ fontFamily: "Barlow-Bold" });
        else if (this.props.Barlow_Light)
            stylesArray.push({ fontFamily: "Barlow-Light" });
        else if (this.props.Barlow_Medium)
            stylesArray.push({ fontFamily: "Barlow-Medium" });
        else if (this.props.Barlow_SemiBold)
            stylesArray.push({ fontFamily: "Barlow-SemiBold" });
        else
            stylesArray.push({ fontFamily: "Barlow-Regular" });

        stylesArray.push({
            color: this.props.color,
            marginTop: this.props.mt,
            marginBottom: this.props.mb,
            marginLeft: this.props.ml,
            marginRight: this.props.mr,
            textAlign: this.props.align
        });
        stylesArray.push(this.props.style);
        return (
            <Text pointerEvents="none" numberOfLines={this.props.sigleLine ? 1 : this.props.numberOfLines || 0} style={stylesArray} onPress={this.props.onPress ? this.onClick : null}>
                {this.props.children}
            </Text>
        );
    }
}

Label.defaultProps = {
    font18Large: false,
    font15Large: false,
    font14Normal: false,
    font11Small: false,
    font9Small: false,
    x10Small: false,
    x12Small: false,
    bold: false,
    bolder: false,
    lighter: false,
    light: false,
    sigleLine: false,
    color: Color.GREY_DARK,


    Barlow_Black: false,
    Barlow_Bold: false,
    Barlow_Light: false,
    Barlow_Medium: false,
    Barlow_SemiBold: false,
    Barlow_Regular: false,

    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
};
Label.propTypes = {
    font18Large: PropTypes.bool,
    font15Large: PropTypes.bool,
    font14Normal: PropTypes.bool,
    font11Small: PropTypes.bool,
    font9Small: PropTypes.bool,
    x10Small: PropTypes.bool,
    x12Small: PropTypes.bool,
    bold: PropTypes.bool,
    bolder: PropTypes.bool,
    light: PropTypes.bool,
    lighter: PropTypes.bool,
    color: PropTypes.string,

    Barlow_Black: PropTypes.bool,
    Barlow_Bold: PropTypes.bool,
    Barlow_Light: PropTypes.bool,
    Barlow_Medium: PropTypes.bool,
    Barlow_SemiBold: PropTypes.bool,
    Barlow_Regular: PropTypes.bool,

    sigleLine: PropTypes.bool,
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
    align: PropTypes.string
};
export default Label;
