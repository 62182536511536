import React, {useContext, useState } from 'react';
import styles from '../stylesheets/RegistrationStyle';
import {
  View, StyleSheet,
  SafeAreaView, Image,
  Text, Dimensions,
  TouchableOpacity, Alert,
  Platform, ActivityIndicator
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import Label from '../components/Label';
import TextBox from '../components/textbox';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { validateCharacter, validateEmail, validName, validatePassword, isSamePassword, occupationName } from '../utils/validation';
import config from '../config.js';
import {UserContext} from '../UserContext';

export default function RegistrationScreen(props) {

  const userData = useContext(UserContext);
      const [fullName, setFullName] = useState("");
      const [emailID, setemailID] = useState("");
      const [rPassword, setPassword] = useState("");
      const [rConfirmPassword, setConfirmPassword] = useState("");
      const [isLoadingSignUp, setLoadingSignUp] = useState("");
      const [originalWidth, setOriginalWidth] = useState(Dimensions.get("window").width);
      
      const setOrganizationData = async () => {
        if(Platform.OS === 'web') {
          let url = window.location.hash;
          url = url.replace('#38;','&');
          url =  window.location.search + url;
          const params = new URLSearchParams(url);
          const org_id = params.get('org_id');
          const org_name = params.get('org_name');
          if(org_id) {
            console.log('Registering Organization');
            await AsyncStorage.setItem('Organization', JSON.stringify({
              org_id,org_name
            }));
          }
        }
    }
    
    setOrganizationData();

      //isRegister: true,

      //fullName: "",
      //emailID: "",
      //rPassword: "",
      //rConfirmPassword: "",
      //isLoadingSignIn: false,
      //isLoadingSignUp: false,
 
      //originalWidth: Dimensions.get("window").width,

  
 const handleText = (name, setValue) => {
  return (text) => setValue(text.replace(/\s/g, ''))
  }

 const handleTextFullName = (name, setValue) => {
    return (text) => setValue(text)
    // return (text) => {
    //   this.setState({ [name]: text })
    // }
  }


  const signUpValidation = () => {
    var message = "";
    if (fullName.length == 0) {
      message = "Please enter a full name"
    } else if (!validName(fullName)) {
      message = "Please enter a only alphabetic"
    } else if (emailID.length == 0) {
      message = "Emailid can not be empty."
    } else if (!validateEmail(emailID)) {
      message = "Please enter a valid email address"
    } else if (rPassword.length == 0) {
      message = "Please enter a Password"
    } else if (rPassword.length < 8) {
      message = "Password length must be between 8 and 128"
    } else if (rConfirmPassword < 8) {
      message = "Confirm Password length must be between 8 and 128"
    } else if (!isSamePassword(rConfirmPassword, rPassword)) {
      message = "Password & confirm Password doesn't match"
    }

    return message;

  }

 
  const createAccount = async () => {

    let messageAlert = signUpValidation()

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        alert(alertText)
      } else {
        Alert.alert(alertTitle, alertText)
      }

      return;

    }

    setLoadingSignUp(true)

    try {

      let response = await fetch(`${config.apiurl}/user`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "fullName": fullName,
          "email": emailID,
          "password": rPassword,
          "confirmPassword": rConfirmPassword
        }),
      });
      let responseJson = await response.json();
      console.log("====== responseJson ======", responseJson);

      setLoadingSignUp(false);

      if (typeof responseJson.access_token != "undefined") {
        console.log("====== responseJson.token ======", responseJson.access_token);
        const user = {'accessToken': responseJson.access_token, 
        'fullName': fullName, 
        "email": emailID, 
        'user_id': responseJson.user_id};
        await AsyncStorage.setItem('user', JSON.stringify(user));
        userData.setUserData(user);
        const organization = JSON.parse(await AsyncStorage.getItem('Organization'));
        if(organization.org_id) { 
            props.navigation.navigate("Organization")
        } else {
            props.navigation.navigate("Dashboard");
        }
      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      console.error("====== error =====", error);
      setLoadingSignUp(false);
    }

  }

 const _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;
    setOriginalWidth(width);
  };


  return (
      <SafeAreaView onLayout={_handleLayout} style={{ flex: 1 }}>
        <View style={[styles.Container, { backgroundColor: originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <KeyboardAwareScrollView
            enableOnAndroid={true} extraHeight={50} extraScrollHeight={50}
            innerRef={ref => {
              scroll = ref
            }}>

            <View style={{
              flexDirection: 'row',
              alignItems: originalWidth >= 991 ? 'center' : 'flex-start',
              justifyContent: 'center',
              paddingTop: originalWidth >= 991 ? 0 : 20,
              height: (Platform.OS == 'android' ? Dimensions.get("window").height - 40 : Dimensions.get("window").height - 120),
              width: '100%',
            }}>

              <View style={styles.registration}>
                <Label Barlow_SemiBold style={[styles.title, {
                  display: (originalWidth < 991 ? 'none' : 'flex'), textAlign: 'center', alignSelf: "center",
                }]}>Registration</Label>
                <TextBox
                  textStyle={styles.inputBox}
                  value={fullName}
                  autoCapitalize={"words"}
                  onChangeText={handleTextFullName("fullName", setFullName)}
                  placeholder="Full Name" />
                <TextBox
                  textStyle={styles.inputBox}
                  value={emailID}
                  onChangeText={handleText("emailID", setemailID)}
                  placeholder="Email" />
                <TextBox
                  textStyle={styles.inputBox}
                  value={rPassword}
                  onChangeText={handleText("rPassword", setPassword)}
                  secureTextEntry={true}
                  placeholder="Password" />
                <TextBox
                  textStyle={styles.inputBox}
                  value={rConfirmPassword}
                  onChangeText={handleText("rConfirmPassword", setConfirmPassword)}
                  secureTextEntry={true}
                  onSubmitEditing={() => {
                    createAccount()
                  }}
                  placeholder="Confirm Password" />
                <TouchableOpacity onPress={createAccount} style={[styles.button, { borderWidth: 1, backgroundColor: Color.White, borderColor: Color.DarkGreen, }]} >
                  {!isLoadingSignUp && <Label style={[styles.btnText, { color: Color.DarkGreen, }]}>Create Account</Label>}
                  {/* {isLoadingSignUp && <ActivityIndicator size="small" color={Color.DarkGreen} />} */}
                </TouchableOpacity>
                <View style={[styles.orLineMain, {
                  marginTop: originalWidth < 991 ? 20 : 60,
                  marginBottom: originalWidth < 991 ? 16 : 16,
                }]}>
                  <View style={styles.borderLine}></View>
                  <Label font14Normal Barlow_Regular style={styles.textOr}>Or</Label>
                  <View style={styles.borderLine}></View>
                </View>
                <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#F34A38', flexDirection: 'row', justifyContent: 'center' }]} >
                  <Image style={styles.iconImg} source={require("./../assets/images/google-plus.png")} />
                  <Text style={styles.btnText}>Sign in with Google</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#007AAA', flexDirection: 'row', justifyContent: 'center' }]}>
                  <Image style={styles.iconImgSecond} source={require("./../assets/images/linkedin.png")} />
                  <Text style={styles.btnText}>Sign in with Linkedin</Text>
                </TouchableOpacity>
                <View style={styles.textAccountMain}>
                  <Text style={styles.textAccount}>You have an account?</Text>
                  <TouchableOpacity onPress={() => {
                    props.navigation.navigate("Login");
                  }}><Text style={{ fontSize: 14, color: '#005961', marginLeft: 2, }}>Login</Text></TouchableOpacity>
                </View>
              </View>

            </View>
          </KeyboardAwareScrollView>


        </View>
      </SafeAreaView >
    );
  }



RegistrationScreen.navigationOptions = {
  header: null,
};
