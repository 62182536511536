import config from '../config';
import {makeFetchCall, postFetchCall} from './BaseService';
export const postInfoRequestAcceptance = async (accessToken, infoRequestId, earnMode) => {
    console.log("Post request received for infoRequestId: " + infoRequestId + " with earnMode as: " + earnMode);
    let response = await postFetchCall(
        config.apiurl + '/info-requests/accept/' + infoRequestId + '/' + earnMode, 
        accessToken,
        {}
    );
    let resp = await response.json();
    console.log("Received response for redemption. Json: " + JSON.stringify(resp));
    return resp;
}
export const postRedemptionRequest = async (accessToken, productId, redemptionMode) => {
    console.log("Post request received for productId: " + productId + " with redemptionMode as: " + redemptionMode);
    let response = await postFetchCall(
        config.apiurl + '/user/rewards/redeem/' + productId + '/' + redemptionMode, 
        accessToken,
        {}
    );
    let resp = await response.json();
    console.log("Received response for redemption. Json: " + JSON.stringify(resp));
    return resp;
}
export const fetchRewardsCatalog = async (props) => {
    let response = await makeFetchCall(config.apiurl + '/rewards', props.accessToken);
    let responseJson = await response.json();
    return responseJson;
}
export const fetchRewardsStatus = async (accessToken) => {
    try {
        let response = await makeFetchCall(config.apiurl + '/user/rewards/status', accessToken);
        let responseJson = await response.json();
        console.log("Received status response: " + JSON.stringify(responseJson));
        return responseJson;
    }catch(e) {
        console.log("Error while fetching status." + e.message);
    }
}
export const fetchInformationRequests = async (accessToken) => {
    let response = await makeFetchCall(config.apiurl + '/info-requests', accessToken);
    let responseJson = await response.json();
    return responseJson;
}
export const fetchPotentialOppurtunities = async (accessToken) => {
    let response = await makeFetchCall(config.apiurl + '/earning-oppurtunities', accessToken);
    let responseJson = await response.json();
    return responseJson;
}

export const fetchAllInformationRequests = async (accessToken) => {
    let response = await makeFetchCall(config.apiurl + '/info-requests?fetch=non_eligible', accessToken);
    let responseJson = await response.json();
    return responseJson;
}

export const fetchCoupons = async (accessToken, limit, after) => {
    let response = await makeFetchCall(config.apiurl + `/reward-coupons?limit=${limit}&after=${after}`, accessToken);
    let responseJson = await response.json();
    return responseJson;
}

export const generateCouponLink = (coupon, accessToken) => {
    let url = config.apiurl + `/rc?reward_id=${coupon.id}&at=${accessToken}&url=${coupon.url}`;
    return url;
}
