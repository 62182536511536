import React,{useContext} from 'react';
import { Text, View } from 'react-native';
import { UserContext } from '../UserContext';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { Color } from '../utils/color';
import OrganizationInteraction from '../components/OrganizationInteraction';
import AuthenticatedLayout from './AuthenticatedLayout';

export default function OrganizationScreen(props) {
    const userData = useContext(UserContext);
    return (
        <>
        <AuthenticatedLayout {...props} title="Organization">
            {originalWidth => (<OrganizationInteraction {...userData.userData} originalWidth={originalWidth} {...props} />)}
        </AuthenticatedLayout>
        </>
    );
}

OrganizationScreen.navigationOptions = {
    header: null, title: 'Organization',
}