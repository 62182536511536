import React from 'react';
import { createBottomTabNavigator, createStackNavigator, createSwitchNavigator } from 'react-navigation';
import { Platform, Image } from 'react-native';
import Label from './../components/Label';
import config from './config';
import DashboardScreen from '../screens/DashboardScreen';
import OrganizationScreen from '../screens/OrganizationScreen';
import SettingsScreen from '../screens/SettingsScreen';
import SummaryScreen from '../screens/SummaryScreen';
import ProductScreen from '../screens/ProductScreen';
import HistoryScreen from '../screens/HistoryScreen';
import RewardScreen from '../screens/RewardScreen';
import NotificationScreen from '../screens/NotificationScreen';
import { Color } from '../utils/color';
import UploadFileScreen from '../screens/UploadFileScreen';
import ProductSubMenu from './ProductSubMenuNavigation'

export const AppWebNavigator = createSwitchNavigator(
    {
        Dashboard: DashboardScreen,
        Organization: OrganizationScreen,
        Summary: SummaryScreen,
        Rewards: RewardScreen,
        Notifications: NotificationScreen,
        UploadFile: UploadFileScreen,
        Product: ProductScreen,
        Settings: SettingsScreen,
        History: HistoryScreen
    }
);

AppWebNavigator.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

AppWebNavigator.path = '';

const DashboardStack = createStackNavigator(
    {
        Dashboard: DashboardScreen
    },
    config
);

DashboardStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/about.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Profile
            </Label>
        );
    },
    gesturesEnabled: false

};

DashboardStack.path = '';

const summaryStack = createStackNavigator(
    {
        Summary: SummaryScreen
    },
    config
);

summaryStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/growth.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Summary
            </Label>
        );
    },
    gesturesEnabled: false

};

summaryStack.path = '';

const RewardsStack = createStackNavigator(
    {
        Rewards: RewardScreen
    },
    config
);

RewardsStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/rewards.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Rewards
            </Label>
        );
    },
    gesturesEnabled: false

};

RewardsStack.path = '';

const NotificationsStack = createStackNavigator(
    {
        Notifications: NotificationScreen
    },
    config
);

NotificationsStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/notification.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Notifications
            </Label>
        );
    },
    gesturesEnabled: false

};

NotificationsStack.path = '';


const ProductStack = createStackNavigator(
    {
        Product: ProductScreen
    },
    config
);

ProductStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/dashboard.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Product
            </Label>
        );
    },
    gesturesEnabled: false

};

ProductStack.path = '';

export const AppBottomNavigator = createBottomTabNavigator(
    {
        Dashboard: DashboardStack,
        Summary: summaryStack,
        Rewards: RewardsStack,
        Notifications: NotificationsStack,
        Product: ProductStack
    }
);

AppBottomNavigator.path='';

