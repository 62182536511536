import config from '../config';
import {makeFetchCall, postFetchCall} from './BaseService';

export const postNotificationToken = async(provider, token, accessToken) => {
    let response = await postFetchCall(config.apiurl + '/user/notification-token', accessToken, {'token': token, 'provider': provider});
    let responseJson = await response.json();
    return responseJson;
}
export const fetchLinkedSocial = async (props) => {
    let response = await makeFetchCall(config.apiurl + '/user/linkedsocial', props.accessToken);
    let responseJson = await response.json();
    console.log("Linked social networks: ", responseJson);
    return responseJson;
}
export const fetchBrandValue = async (props) => {
    let response = await makeFetchCall(config.apiurl + '/user/brandvalue', props.accessToken);
    let responseJson = await response.json();
    return responseJson;
}

export const fetchBehavior = async (category, accessToken) => {
    const response = await makeFetchCall(config.apiurl + "/user/behavior/" + category, accessToken)
    const responseJson = await response.json();
    return responseJson;
}
export const fetchBehaviorKeys = async (accessToken) => {
    let response = await makeFetchCall(config.apiurl + "/user/behavior-keys", accessToken);
    response = await response.json();
    return response;
}