import { Platform, Alert } from 'react-native';
export const showConfirm = (message, processConfirmation) => {
    if (Platform.OS === "web") {
        if (window.confirm(message)) {
            processConfirmation();
            return;
        }
    }
    Alert.alert("Confirm redemption:", message,
        [
            { text: "Cancel", onPress: () => { } },
            { text: "Proceed", onPress:processConfirmation  }
        ]);
}

export const showMessage = (title, msg) => {
    console.log("Inside showMessage");
    if (Platform.OS === "web") {
        console.log("Inside web block");
        window.alert(msg);
    } else {
        Alert.alert(title, msg);
    }
}