import React, { useState, useEffect, useContext, Fragment } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { TouchableOpacity, Dimensions, SafeAreaView, Text, View, Linking, Platform, Alert } from 'react-native';
import { ButtonGroup, Card, Image, Button } from 'react-native-elements';
import { Color } from '../utils/color';
import { EvilIcons, Foundation } from '@expo/vector-icons';
import AuthenticatedLayout from './AuthenticatedLayout';
import { postInfoRequestAcceptance, fetchInformationRequests, 
    fetchRewardsStatus, postRedemptionRequest, 
    fetchRewardsCatalog,fetchCoupons } from '../services/RewardService';
import config from '../config';
import { UserContext } from '../UserContext';
import { showConfirm, showMessage } from '../utils/UIUtil';
import { InformationRequestDetails } from '../components/InformationRequestDetails';
import RewardHomeScreen from './RewardHomeScreen';
import Coupons from '../components/Coupons';

export default function Rewards(props) {
    const userData = useContext(UserContext);
    if(!userData.userData.user_id) {
        return <RewardHomeScreen {...props} />
    }
    
    return (
        <AuthenticatedLayout {...props} title="Rewards">
            {originalWidth => (<RewardsContent {...userData.userData} originalWidth={originalWidth} />)}
        </AuthenticatedLayout>
    );
}

const RewardsContent = (props) => {
    const [rewardsCatalog, setRewardsCatalog] = useState([]);
    const [rewardsStatus, setRewardsStatus] = useState({});
    const [informationRequests, setInformationRequests] = useState([]);
    useEffect(() => {
        fetchRewardsStatus(props.accessToken).then(res => setRewardsStatus(res));
        fetchRewardsCatalog(props).then(res => setRewardsCatalog(res.catalog));
        fetchInformationRequests(props.accessToken).then(res => setInformationRequests(res));
    }, []);
    const rewardsData = { status: rewardsStatus, informationRequests: informationRequests, catalog: rewardsCatalog };
    return (
        <View style={[{ flexDirection: 'column', margin: 0 }]}>
            <RewardTabs  {...props} rewardsData={rewardsData} setRewardsStatus={setRewardsStatus} setInformationRequests={setInformationRequests} />
        </View>
    )
}

const RewardStatus = (props) => {
    return (
        <View style={[{ backgroundColor: Color.RewardsStatusBG, flexDirection: props.originalWidth > 767 ? 'row' : 'column', margin: 5, height: 180 }]}>
            <View style={{ flex: props.originalWidth > 767 ? 5 : 1, flexDirection: 'row' }}>
                <View style={[{ flex: 1, padding: 20 }]}>
                    <View style={{ height: 80, width: 80, borderRadius: 40, backgroundColor: Color.BlackLight, padding: 15 }}>
                        <EvilIcons name="trophy" size={50} color={Color.White} />
                    </View>
                </View>
                <View style={[{ flex: 9, flexDirection: 'column', padding: 15 }]}>
                    <Text style={{ fontWeight: 'bold', fontSize: 18 }}>{props.rewardsData.status.userType}</Text>
                    <Text>{props.rewardsData.status.earnedPoints}</Text>
                    <Text>{props.rewardsData.status.goalDescription}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                { Number(props.rewardsData.status.dollarValue) > 0 && 
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ height: 20, width: 20, borderRadius: 10, margin: 5, backgroundColor: Color.White }} />
                    <Text style={{ color: Color.White, fontSize: 14 }}>$ {props.rewardsData.status.dollarValue}</Text>
                </View> }
                { Number(props.rewardsData.status.earnedPoints) > 0 && 
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ height: 20, width: 20, borderRadius: 10, margin: 5, backgroundColor: Color.White }} />
                    <Text style={{ color: Color.White, fontSize: 14 }}>{props.rewardsData.status.earnedPoints} points</Text>
                </View> }
                { Number(props.rewardsData.status.cryptoValue) > 0 &&
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ height: 20, width: 20, borderRadius: 10, margin: 5, backgroundColor: Color.White }} />
                    <Text style={{ color: Color.White, fontSize: 14 }}>{props.rewardsData.status.cryptoValue} coins</Text>
                </View> }
            </View>
        </View>
    );
}

const RewardTabs = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <View style={[{ flexDirection: 'column', margin: 5 }]}>
            <RewardOptions {...props} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
            {selectedIndex == 0 && <EarnRewards {...props} setInformationRequests={props.setInformationRequests} />}
            {selectedIndex == 1 && <RedeemRewards {...props} />}
        </View>
    )
}

const RewardOptions = (props) => {
    const buttons = ["Earn", "Redeem"];
    return (
        <ButtonGroup
            onPress={props.setSelectedIndex}
            selectedIndex={props.selectedIndex}
            buttons={buttons}
            containerStyle={{ height: 65 }}
            selectedButtonStyle={{ backgroundColor: Color.DarkGreen }}
        />
    );
}

const RedeemRewards = (props) => {
    const products = props.rewardsData.catalog;
    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {products.map((product, index) => {
                return (
                    <Card containerStyle={{ width: 400, }} key={index}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <Image
                                    resizeMode="contain"
                                    style={{ height: 100 }}
                                    source={{ uri: config.apiurl + "/static/" + product.productLogo }} />
                            </View>
                            <View style={{ flex: 3, flexDirection: 'column' }}>
                                <Text>{product.productName}</Text>
                                <Text>{product.description}</Text>
                            </View>
                        </View>
                        <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                            <Text style={{ justifyContent: 'center', fontWeight: 'bold' }}>Buying options</Text>
                        </View>
                        <RewardAction
                            dollarPrice={product.dollarPrice}
                            pointsPrice={product.pointsPrice}
                            cryptoPrice={product.cryptoPrice}
                            actionParams={{ productId: product.id, productName: product.productName }}
                            mode="redeem"
                            accessToken={props.accessToken}
                            setRewardsStatus={props.setRewardsStatus} />
                    </Card>
                );
            })
            }
        </View>
    );
}

const EarnRewards = (props) => {
    const [informationRequestDetails, setInformationRequestDetails] = useState({ show: false, request: null });
    const handleInformationRequestSelection = (infoRequest) => {
        setInformationRequestDetails({
            show: infoRequest ? true : false,
            request: infoRequest
        });
    }
    return (
        <>
            <Coupons {...props} />
            {informationRequestDetails.show && <InformationRequestDetailedView {...props} request={informationRequestDetails.request} setInformationRequest={handleInformationRequestSelection} />}
            {!informationRequestDetails.show && <InformationRequestList {...props} setInformationRequest={handleInformationRequestSelection} />}
        </>
    );
}


const InformationRequestDetailedView = (props) => {
    const request = props.request;

    const setInformationRequestsAndGoBack = (requests) => {
        props.setInformationRequests(requests);
        props.setInformationRequest(null);
    }
    return (
        <Card containerStyle={[{ alignItems: 'center' }]}>
            <InformationRequestDetails {...props} request={request} />
            <View style={{ alignItems: 'center' }}>
                <Text style={{ justifyContent: 'center', paddingBottom: 10 }}>Choose any one of them:</Text>
            </View>
            <RewardAction
                dollarPrice={request.dollarPrice}
                pointsPrice={request.pointsPrice}
                cryptoPrice={request.cryptoPrice}
                mode="earn"
                actionParams={{ infoRequestId: request.id, companyName: request.companyName }}
                accessToken={props.accessToken}
                setRewardsStatus={props.setRewardsStatus}
                setInformationRequests = {setInformationRequestsAndGoBack}
            />
            <Button buttonStyle={{ width: 100, backgroundColor: '#979589', alignSelf:'center', marginTop:30 }} title="Back" accessibilityRole="button" onPress={() => props.setInformationRequest(null)} />
        </Card>
    );
}
const InformationRequestList = (props) => {
    const inforequests = props.rewardsData.informationRequests;
    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {inforequests.map((request, index) => {
                return (
                    <Card containerStyle={{ width: 400 }} key={index}>
                        <TouchableOpacity style={{ flex: 1, flexDirection: 'column', alignItems: 'center', paddingBottom: 10 }}
                            onPress={() => props.setInformationRequest(request)}>
                            <Text style={{ justifyContent: 'center', fontSize: 18 }}>{request.companyName}</Text>
                        </TouchableOpacity>
                        <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center', paddingBottom: 20 }}>
                            <Text style={{ justifyContent: 'center' }}>{request.description}</Text>
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ justifyContent: 'center', paddingBottom: 10 }}>Choose any one of them:</Text>
                        </View>
                        <RewardAction
                            dollarPrice={request.dollarPrice}
                            pointsPrice={request.pointsPrice}
                            cryptoPrice={request.cryptoPrice}
                            mode="earn"
                            actionParams={{ infoRequestId: request.id, companyName: request.companyName }}
                            accessToken={props.accessToken}
                            setRewardsStatus={props.setRewardsStatus}
                            setInformationRequests={props.setInformationRequests}
                        />
                    </Card>
                );
            })
            }
        </View>
    );
}
const RewardAction = (props) => {
    const handleRedemptionPost = (accessToken, productId, redemptionMode) => {
        postRedemptionRequest(accessToken, productId, redemptionMode).then(res => {
            showMessage(res.code, res.message);
            if (res.rewardsStatus)
                props.setRewardsStatus(res.rewardsStatus);
        }).catch(error => showMessage("Error", "Error while making post redemption call."));
    }
    const handleEarnPost = (accessToken, infoRequestId, earnMode) => {
        postInfoRequestAcceptance(accessToken, infoRequestId, earnMode).then(res => {
            showMessage(res.code, res.message);
            if (res.rewardsStatus) {
                props.setRewardsStatus(res.rewardsStatus);
                fetchInformationRequests(props.accessToken).then(res => props.setInformationRequests(res));
            }
        }).catch(error => showMessage("Error", "Error while making post redemption call."));
    }
    const processRedemption = (productId, productName, redemptionMode) => {
        const message = "You are about to redeem " + productName + ", using your " + redemptionMode + " balance. Do you want to proceed?";
        showConfirm(message, () => handleRedemptionPost(props.accessToken, productId, redemptionMode));
    }
    const processRequestAcceptance = (infoRequestId, companyName, value, earnMode) => {
        const message = "You are about to accept request sent by " + companyName + ", for " + value + " " + earnMode + ". Do you want to proceed?";
        showConfirm(message, () => handleEarnPost(props.accessToken, infoRequestId, earnMode));
    }
    const handleAction = (actionParams, value, mode) => {
        if (props.mode === "redeem") {
            processRedemption(actionParams.productId, actionParams.productName, mode);
        } else if (props.mode === "earn") {
            processRequestAcceptance(actionParams.infoRequestId, actionParams.companyName, value, mode);
        }
    }
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <Button buttonStyle={{ width: 100, backgroundColor: '#185b61' }} title={props.dollarPrice} accessibilityRole="button" onPress={() => handleAction(props.actionParams, props.dollarPrice, 'USD')} />
            <Button buttonStyle={{ width: 100, backgroundColor: '#979589' }} title={props.pointsPrice} accessibilityRole="button" onPress={() => handleAction(props.actionParams, props.pointsPrice, 'POINTS')} />
            {props.cryptoPrice && <Button buttonStyle={{ width: 100, backgroundColor: '#f8647e' }} title={props.cryptoPrice} accessibilityRole="button" onPress={() => handleAction(props.actionParams, props.cryptoPrice, 'CRYPTO')} />}
        </View>
    );
}