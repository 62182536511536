import {createStackNavigator} from 'react-navigation';
import config from './config';
import AuthLoadingScreen from '../screens/AuthLoadingScreen';

const LoadingNavigation = createStackNavigator(
    {
        AuthLoadingScreen: AuthLoadingScreen,
    },
    config
);

LoadingNavigation.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

LoadingNavigation.path = '';
export default LoadingNavigation;