import React, { useEffect, useState, useContext } from 'react';
import {  Text, View, StyleSheet, TouchableOpacity , Alert, ScrollView, Dimensions  } from 'react-native';
import { Modal, TouchableHighlight } from 'react-native';
import { Card, Icon } from 'react-native-elements';
import { List } from 'react-native-paper'

export default function ProductSubMenuNavigation(props) {
    const [modalVisible, setModalVisible] = useState(true);
    const list = [
        {
          title: 'Appointments',
          icon: 'av-timer'
        },
        {
          title: 'Trips',
          icon: 'flight-takeoff'
        }
      ]
    
    return (
        <>
      <View style={styles.centeredView}>
      { modalVisible && <Modal
        animationType="slide"
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
                <Text onPress={() => props.navigation.navigate('Product')}> Product Search </Text>
                <Text> History </Text>
                <Text> V loader </Text>
          </View>
        </View>
      </Modal> }
      </View>
      </>
    );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#eee', width:'100%' },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    zIndex:9999,
    position:'absolute',
    bottom:20,
    right:5
  },
  modalView: {
    width: Dimensions.get("window").width * 0.6,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: '#F194FF',
    borderRadius: 20,
    padding:0,
    elevation: 2,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  }
});