import config from '../config';
import {makeFetchCall, postFetchCall} from './BaseService';

export const fetchNotifications = async (accessToken) => {
    console.log("Inside fetchNotifications method call.");
    let response = await makeFetchCall(config.apiurl + '/notifications', accessToken);
    let responseJson = await response.json();
    return responseJson;
}
export const markAsRead = async (accessToken, notificationId) => {
    let response = await postFetchCall(config.apiurl + '/notifications/' + notificationId + '/mark-as-read', accessToken, {});
    let responseJson = await response.json();
    console.log(JSON.stringify(responseJson));
}