import React,{useState, useContext} from 'react';
import {ScrollView, Dimensions, SafeAreaView, View} from 'react-native';
import { BaseStyle } from '../stylesheets/BaseStyle';
import CXSphereBanner from '../components/CXSphereBanner';
import AppSideMenu from '../components/AppSideMenu';
import useNativeLayout from '../hooks/NativeLayoutHook';
import { Color } from '../utils/color';
import { UserContext } from '../UserContext';

const checkAuthentication = (user, props) => {
    console.log("Inside checkAuthentication.");
    if (Object.keys(user).length === 0) {
        console.log("Inside unAuthenticated condition block.");
       props.navigation.navigate("Login");
    }
}
export default function AuthenticatedLayout(props) {
    const [originalWidth, originalHeight, handleLayout] = useNativeLayout();
    const [menuExpanded, setMenuExpanded] = useState(false);
    const toggleMenu = () => {
        setMenuExpanded(!menuExpanded);
    }
    const userData = useContext(UserContext);
    checkAuthentication(userData.userData, props);
    return (
        <SafeAreaView style={[{flexDirection: originalWidth<767 ? 'column' : 'row'}]} onLayout={handleLayout}>
            {originalWidth > 767 && <AppSideMenu {...props} height={originalHeight}/>}
            <ScrollView style={[{flexDirection:'column'}]}>
                <CXSphereBanner title={props.title} {...props} />
                {props.children(originalWidth)}
            </ScrollView>
        </SafeAreaView>
    );
}