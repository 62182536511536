import React, { useState, useEffect } from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native';
import {Card, Button } from 'react-native-elements';
import { Color } from '../utils/color';
import {postInfoRequestAcceptance, fetchAllInformationRequests, fetchInformationRequests, fetchPotentialOppurtunities } from '../services/RewardService';
import { showConfirm, showMessage } from '../utils/UIUtil';
import { InformationRequestDetails } from '../components/InformationRequestDetails';

export const RevenuePotential = (props) => {
    const [informationRequests, setInformationRequests] = useState([]);
    const [potentialOpportunities, setPotentialOpportunities] = useState({});
    const [displayAccept, setDisplayAccept] = useState(true);

    const _fetchAndSetInformationRequests =() => fetchInformationRequests(props.accessToken).then(res => setInformationRequests(res));
    const _fetchAndSetPotentialOppurtunities =() => fetchPotentialOppurtunities(props.accessToken).then(res => setPotentialOpportunities(res));

    useEffect(() => {
        _fetchAndSetInformationRequests();
        _fetchAndSetPotentialOppurtunities();
    }, []);
    const updateData = () => {
        _fetchAndSetInformationRequests();
        _fetchAndSetPotentialOppurtunities();
    }
    const handleEligibilityChange = (accessToken, fetchEligibleRequests) => {
        setDisplayAccept(fetchEligibleRequests);
        fetchEligibleRequests ? 
        _fetchAndSetInformationRequests() : 
        fetchAllInformationRequests(props.accessToken).then(res => setInformationRequests(res));
    }
    const [infoReqDetailsOptions, setInfoReqDetailsOptions] = useState({show: false, infoReq:null})
    const toggleInfoReqDetails = (_infoReq) => {
        setInfoReqDetailsOptions({
            show: (_infoReq !== null),
            infoReq: _infoReq
        });
    }
    return (
        
        <View style={{ flexDirection: 'column', margin: 5 }}>
            <BreadCrumbs {...props} />
            <RevenuePotentialOptions {...props} potentialOpportunities={potentialOpportunities} handleEligibilityChange = {handleEligibilityChange} />
            {infoReqDetailsOptions.show && <InformationRequestDetailedView {...props} request={infoReqDetailsOptions.infoReq} toggleInfoReqDetails={toggleInfoReqDetails} /> }
            {!infoReqDetailsOptions.show &&  
            <RevenuePotentialList {...props} 
                informationRequests={informationRequests} 
                fetchAndSetInformationRequests={_fetchAndSetInformationRequests} 
                toggleInfoReqDetails={toggleInfoReqDetails} 
                displayAccept={displayAccept}
                updateData={updateData}/>}
        </View>
    );
}
const BreadCrumbs = (props) => {
    return (
        <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => props.toggleRevenuePotential(false)} accessibilityRole="button">
                <Text style={{ fontSize: 20 }}>Profile</Text>
            </TouchableOpacity>
            <Text style={{ fontSize: 20 }}>-></Text>
            <Text style={{ fontSize: 20 }}>Revenue potential</Text>
        </View>
    );
}
const RevenuePotentialOptions = (props) => {
    return (
        <View style={{ height: 80, backgroundColor: Color.DarkGreen, flexDirection: 'row', alignItems:"center", justifyContent:"space-between" }}>
            <TouchableOpacity onPress={() => props.handleEligibilityChange(props.accessToken, true)}>
            <Text style={{ color: Color.White, fontSize: 15, marginLeft:20}}>Eligible</Text>
            </TouchableOpacity>
            <Text style={{ color: Color.White, fontSize: 11, marginLeft:20, backgroundColor:"#00000066", width:80}}>
                ${props.potentialOpportunities.eligible_currency}
                <Text style={{ color: Color.Orange, fontSize: 11, marginLeft:20, backgroundColor:"#00000066", borderRadius:10}}>{props.potentialOpportunities.eligible_count}</Text>  
            </Text>
            
            <TouchableOpacity onPress={() => props.handleEligibilityChange(props.accessToken, false)}>
            <Text style={{ color: Color.White, fontSize: 15, paddingLeft:60 }}>Upgrade to Eligible</Text >
            </TouchableOpacity>
            <Text style={{ color: Color.White, fontSize: 11, marginLeft:20, backgroundColor:"#00000066"}}>
                ${props.potentialOpportunities.non_eligible_currency}
                <Text style={{ color: Color.Orange, fontSize: 11, marginLeft:20, backgroundColor:"#00000066", borderRadius:10}}>{props.potentialOpportunities.non_eligible_count}</Text>  
            </Text>
            <Text style={{ color: Color.White, fontSize: 11, fontWeight: 'bold', flex: 1 }}></Text >
        </View>
    );
}
const ListHeader = (props) => {
    return (
        <View style={{ flexDirection: 'row', backgroundColor: Color.DarkGreen, marginTop: 2, height: 60, alignItems:"center" }}>
            <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold', color: Color.White,marginLeft:20 }}>Company Name</Text>
            <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold', color: Color.White }}>Offered Price</Text>
            <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold', color: Color.White }}>Closing Date</Text>
            <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold', color: Color.White }}>Status</Text>
            <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold', color: Color.White }}></Text>
        </View>
    )
}
const handleAcceptance = (accessToken, infoRequest, onSuccess) => {
    const message = "You are about to accept request sent by " + infoRequest.companyName + ", for " + infoRequest.dollarPrice + " " + "USD. Do you want to proceed?";
    showConfirm(message, () => {
        postInfoRequestAcceptance(accessToken, infoRequest.id, "USD").then(res => {
            showMessage(res.code, res.message);
            if(res.code === "Success") {
                onSuccess();
            }
        });
    });
}
const InformationRequestLineItem = (props) => {
    return (
        <View style={{flexDirection:'row', marginTop:20}}>
            <Text style={{ flex: 1, fontSize: 14, marginLeft:20}}>{props.informationRequest.companyName}</Text>
            <Text style={{ flex: 1, fontSize: 14}}>${props.informationRequest.dollarPrice}</Text>
            <Text style={{ flex: 1, fontSize: 14}}>{props.informationRequest.endDate}</Text>
            <Text style={{ flex: 1, fontSize: 14}}>Active</Text>
            <View style={{flex:1,flexDirection:'row'}}>
            {   
                props.displayAccept == true &&
                <Button buttonStyle={{ width: 70, backgroundColor: '#185b61' }} title="Accept" accessibilityRole="button" onPress={() => handleAcceptance(props.accessToken, props.informationRequest, props.updateData) } />
            }
            <Button style={{width:70, marginLeft:5}} title="Details" accessibilityRole="button" onPress={() => props.toggleInfoReqDetails(props.informationRequest)} />
            </View>
        </View>
    );
}
const InformationRequestDetailedView = (props) => {
    const request = props.request;
    return (
        <Card containerStyle={[{ alignItems: 'center' }]}>
            <InformationRequestDetails {...props} request={request} />
            <Button buttonStyle={{ width: 100, backgroundColor: '#979589', alignSelf:'center', marginTop:30 }} title="Back" accessibilityRole="button" onPress={() => props.toggleInfoReqDetails(null)} />
        </Card>
    );
}
const RevenuePotentialList = (props) => {
    const informationRequests = props.informationRequests;
    console.log("Count of info requests: " + JSON.stringify(informationRequests));
    return (
        <FlatList
            ListHeaderComponent={ListHeader}
            data={informationRequests}
            renderItem={({ item }) => <InformationRequestLineItem informationRequest={item} {...props} />} />
    );
}