import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppLoading from 'expo-app-loading';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';

export default function CXSphereAppLoading(props) {
    return (
        <AppLoading
            startAsync={
                async () => {
                    const user = JSON.parse(await AsyncStorage.getItem('user'));
                    if (user) {
                        props.setUserData(user);
                    }
                    await loadResources()
                }
            }
            onError={handleLoadingError}
            onFinish={() => handleFinishLoading(props.setLoadingComplete)}
        />
    )
}

async function loadResources() {
    console.log("Loading resources.");
    await Promise.all([
        Asset.loadAsync([
            require('../assets/images/robot-dev.png'),
            require('../assets/images/robot-prod.png'),
        ]),
        Font.loadAsync({
            // This is the font that we are using for our tab bar
            ...Ionicons.font,
            // We include SpaceMono because we use it in HomeScreen.js. Feel free to
            // remove this if you are not using it in your app
            'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
            'Barlow-Black': require('../assets/fonts/Barlow-Black.otf'),
            'Barlow-Bold': require('../assets/fonts/Barlow-Bold.otf'),
            'Barlow-Light': require('../assets/fonts/Barlow-Light.otf'),
            'Barlow-Medium': require('../assets/fonts/Barlow-Medium.otf'),
            'Barlow-Regular': require('../assets/fonts/Barlow-Regular.otf'),
            'Barlow-SemiBold': require('../assets/fonts/Barlow-SemiBold.otf')

        }),
    ]);
}

function handleLoadingError(error) {
    console.error("Error while loading resources. " + error)
}
function handleFinishLoading(setLoadingComplete) {
    console.log("Finished loading resources.");
    setLoadingComplete(true);
}