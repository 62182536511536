import React, { useState, useContext, useEffect } from 'react';
import styles from '../stylesheets/HomeStyle';
import {
  View,
  SafeAreaView,
  Text,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  ScrollView,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator,
  Linking,
  useWindowDimensions
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DataTable, Card } from 'react-native-paper';
import Label from '../components/Label';
import TextBox from '../components/textbox';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { validateCharacter, validateEmail, validName, validatePassword, isSamePassword, occupationName } from '../utils/validation';
import SocialSignIn from '../components/SocialSignIn';
import config from '../config.js';
import useNativeLayout from '../hooks/NativeLayoutHook';
import {UserContext} from '../UserContext';
import {fetchProduct } from '../services/ProductService';
import { EvilIcons } from '@expo/vector-icons';

export default function ProductHomeScreen(props) {
    const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();
    const [sortName, setSortName] = useState(null);
    const [sortColumn, setSortColumn] = useState(0);
    const [sortType, setSortType] = useState(0);
    const [products, setProducts] = useState({
      header: [], 
      content:[]
    }); 
  
    useEffect( () => {
      fetchProduct(50, 0,'','').then(arg => setProducts(arg))
      }, []);

      const addButton = (index) => (
        <TouchableOpacity onPress={ async () => {
          await AsyncStorage.setItem('fromProduct', true);
          props.navigation.navigate("Login")
          }
        }>
          <View style={{ width: 100, height: 30, backgroundColor: 'rgb(0, 89, 97)',  borderRadius: 10, padding:10 }}>
            <Text style={{textAlign: 'center', color: '#fff', fontSize:12}}>Order Now</Text>
          </View>
        </TouchableOpacity>
      );

      const addViewButton = (element) => (
          <View style={{ width: 100, height: 30, backgroundColor: 'rgb(0, 89, 97)',  borderRadius: 10, padding:10 }}>
            <Text style={{textAlign: 'center', color: '#fff', fontSize:12}}>
              <a href={element['url']} target='_blank' style={{ color:'#fff', textDecoration:'none'}}> View Now </a>  
            </Text>
          </View>
      );
      
    const popupwindow = (url, title, w, h) => {
        var left = (screen.width/2)-(w/2);
        var top = (screen.height/2)-(h/2);
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    } 

    const _addToRow = (element, key) => {
        const rows = [];
        for(let x in element) {
            if(!(x === 'id' || x === 'store_id' || x === 'url' || x=== 'type' || x === 'lat' || x === 'long' || x === 'store_latitude' || x === 'store_longitude'))
            if(x.indexOf('logo') === 0) {
              rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}> 
              <Image style={{
                  width: 50,
                  height: 40,
                  resizeMode: 'contain'
              }} source={{ uri: (element[x])}} />
              </DataTable.Cell>)
            } else {
              rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}> 
              <Text style={{
                  fontFamily: 'Barlow-Regular', fontSize:12
              }}>{ element[x] } </Text> </DataTable.Cell>);
          }
        }

        if(element['type'] === 'digital') {
          rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>{addViewButton(element)}</DataTable.Cell>);
          rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}></DataTable.Cell>);
        } else {
          rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>{addButton(key)}</DataTable.Cell>);
          rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>
            <Text style={{ alignSelf: 'center' }} onPress={() => popupwindow('https://map.cxsphere.com/?addr='+element['address']+'&lat='+element['lat']+'&long='+element['long'], 'cxsphere map', '400','400')}>
                            <EvilIcons name="location" style={{ fontSize:20 }} />
            </Text>
          </DataTable.Cell>);
        }
        return rows;
    }

    const decideSort = (key, isortName) => {
      if(sortColumn === key) {
          setSortColumn(key)
          setSortType(sortType === 'ascending' ? 'decending' : 'ascending')
      } else {
          setSortType('ascending')
          setSortColumn(key)
      }
      setSortName(isortName.toLowerCase().replace(' ', '_'))
  }

  const sortByProperty = (property) => {
      if(sortType === 'ascending') { 
          return function(a,b){  
          if(a[property] > b[property])  
              return 1;  
          else if(a[property] < b[property])  
              return -1;  
          return 0;  
          }
      } else { 
          return function(a,b) {  
              if(a[property] < b[property])  
                  return 1;  
              else if(a[property] > b[property])  
                  return -1;  
              return 0;  
              }
      }  
    }

    products.content && products.content.sort(sortByProperty(sortName))
    return (
      <SafeAreaView onLayout={_handleLayout}>
      { Platform.OS === "web" && <>  
        <View style={{ flex:1, flexDirection:'row' }}>
          <View style={{
            backgroundColor: Color.White,
            width:'90%',
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "flex-start"
          }}>
            <Image style={{
                width: 100,
                height: 80,
                resizeMode: 'contain'
            }} source={require("../assets/images/logo.png")} />
          </View>
          <View style={{
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "flex-end"
          }}>
          <TouchableOpacity  onPress={()=>props.navigation.navigate("Login")}>
          <Text> Login / Register </Text>
          </TouchableOpacity>
          </View>  
          </View>
        <View style={{backgroundColor:'#eee', padding:30, height:500, overflow:'scroll'}}> 
                  <ScrollView horizontal={false}>
                        <DataTable>
                        <DataTable.Header style={{ backgroundColor:'#eee'}}>
                         { products.header.map((item, key) => {
                            return ( <DataTable.Title 
                                sortDirection={sortColumn === key ? sortType : false }
                                onPress={() => decideSort(key, item)}    
                                style={{ justifyContent:'space-evenly'
                                }}>
                            <Text style={{
                                fontFamily: 'Barlow-Regular', 
                                color: Color.DarkGreen, 
                                fontWeight: '600'}} >{item} </Text>
                            </DataTable.Title>
                            )     
                         })} 
                        </DataTable.Header>

                        {products.content.map((element, key) => 
                            <DataTable.Row style={{backgroundColor:'#fff'}}>
                            {
                                _addToRow(element, key)
                            }
                            </DataTable.Row>   
                        )}
                       </DataTable>
                    </ScrollView>
                        </View>
        </>                    
      }
      { ( Platform.OS === "android" || Platform.OS === "ios" )  && <ScrollView>
        <View style={{ flexDirection:'row' }}>
          <View style={{
            backgroundColor: Color.White,
            paddingTop: (Platform.OS == 'ios' ? 20 : 40),
            paddingBottom: 20,
            width: '70%',
            justifyContent: 'flex-start',
            alignItems: "flex-start"
          }}>
            <Image style={{
                width: 100,
                height: 80,
                resizeMode: 'contain'
            }} source={require("../assets/images/logo.png")} />
          </View>
          <View style={{
            backgroundColor: Color.White,
            paddingTop: 80,
            justifyContent: 'center',
            alignItems: "flex-end"
          }}>
          <TouchableOpacity  onPress={() => props.navigation.navigate('Login')}>
              <Text> Login / Register </Text>
          </TouchableOpacity>
          </View>
        </View>  
        <View style={{ flexDirection:'row', backgroundColor:'#eee', padding: 10 }}>
            <Text style={{ fontSize: 20, fontFamily: 'Barlow-Regular' }}> About 844 results </Text>
        </View>
        <View style={{ flexDirection:'row', backgroundColor:'#fff', marginTop: 10, padding:10 }}>
            <View style={{ width:'40%'}}>
              <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Store </Text>
            </View>
            <View style={{ width:'30%'}}>
              <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Price Range </Text>
              </View>
            <View style={{ width:'30%', alignItems:'flex-end'}}>
                <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Distance </Text>
            </View>
        </View>
        {
           products.content.map((item, key) => 
            <View key={key} style={{ flexDirection:'row', backgroundColor:'#fff', padding:10, borderBottomWidth:1, borderColor:'#eee' }}>
                <View style={{ width:'40%'}}>
                  <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular', fontWeight:'600' }}> {item.store_name} </Text>
                  <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.address} </Text>
                  <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.contact} </Text>
                </View>
                <View style={{ width:'30%'}}>
                  <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.price_range} </Text>
                  </View>
                { item.type === 'physical' && ( 
                <View style={{ width:'30%', alignItems:'flex-end'}}>
                    <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.distance} </Text>
                    <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular', paddingTop: 30, color:Color.DarkGreen }}> Pre Order Now! </Text>
                </View> )}
                { item.type === 'digital' && ( 
                <View style={{ width:'30%', alignItems:'flex-end'}}>
                    <Text onPress={() => Linking.openURL(item.url)} 
                    style={{ fontSize: 12, fontFamily: 'Barlow-Regular', paddingTop: 30, color:Color.DarkGreen }}> View Now! </Text>
                </View> )}
            </View>      
           )
        }
        </ScrollView>
      }
      </SafeAreaView>
    );
}

ProductHomeScreen.navigationOptions = {
  header: null,
};
