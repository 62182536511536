import React,{useContext} from 'react';
import { UserContext } from '../UserContext';
import SettingsContent from '../components/SettingsContent';
import AuthenticatedLayout from './AuthenticatedLayout';

export default function SettingsScreen(props) {
    const userData = useContext(UserContext);
    return (
        <>
        <AuthenticatedLayout {...props} title="Settings">
            {originalWidth => (<SettingsContent {...userData.userData} originalWidth={originalWidth} {...props} />)}
        </AuthenticatedLayout>
        </>
    );
}
SettingsScreen.navigationOptions = {
    header: null, title: 'Settings',
}