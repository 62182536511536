import React, { useEffect, useState, useContext } from 'react';
import { BaseStyle } from '../../stylesheets/BaseStyle';
import {  Text, View, StyleSheet, TouchableOpacity , Alert, ScrollView, Dimensions, Platform  } from 'react-native';
import { Modal, TouchableHighlight } from 'react-native';
import { DataTable, Card } from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import { Image, Button, Icon } from 'react-native-elements';
import { Color } from '../../utils/color';
import config from '../../config';
import { UserContext } from '../../UserContext';
import {fetchProduct, addToCart } from '../../services/ProductService';
import TextBox from '../textbox';
import { EvilIcons } from '@expo/vector-icons';

export default function ProductContent(props) {
    const [start, setStart] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [isbuttonClick, setIsbuttonClick] = useState(false)
    const [inputSearch, setInputSearch] = useState('')
    const [inputCouponCode, setInputCouponCode] = useState('')
    const [searchHeader, setSearchHeader] = useState('');
    const [sortName, setSortName] = useState(null);
    const [sortColumn, setSortColumn] = useState(0);
    const [sortType, setSortType] = useState(0);
    const [selectedRow, setSelectedRow] = useState([])
    const [isAdd2CartClick, setisAdd2CartClick] = useState(false)
    const [products, setProducts] = useState({
        header: [], 
        content:[],
        rawProducts:[]
    }); 
    
    const numberofRows = 25;
    const [orders, setOrders] = useState([]);
    
    useEffect( () => {
      fetchProduct(numberofRows,start, searchHeader, inputSearch).then(arg => {
        setProducts(arg);
        setIsbuttonClick(false);
      })
    }, [start, isbuttonClick]);

   
   useEffect( () => {
      if(orders.length > 0)
        addToCart(orders, props.user_id, inputCouponCode).then(arg => {
          setOrders([])
          setModalVisible(false)
        })   
    }, [isAdd2CartClick]);
  
    const submitSearch = () => {
       const bool = isbuttonClick;
       setIsbuttonClick(!bool);
    } 

    const setItems = (visible, id, store_id) => {
      setModalVisible(visible); 
      setSelectedRow([...selectedRow, id]);
      const reOrder = reformOrder(id, store_id, products.rawProducts)
      setOrders(reOrder);
    }

    const getProduct = (id, store_id, rawData) => {
        for(let i=0;i<rawData.length;i++) {
           if(rawData[i].cxs_product_id === id && rawData[i].store_details.store_id === store_id) {
              return rawData[i]
           }
        }
        return null;
    }

    const reformOrder = (id, store_id, rawData ) => {
        let data = orders;
        
        if(!getProduct(id, store_id, rawData))
        return data;

        if(!data.length) {
          let store = getProduct(id, store_id,rawData)
          store.items = [];
          store.names = [store.product_name]
          store.items.push({
            'oprice': store.attribute_data.price,
            'currency': store.attribute_data.currency,
            'item': store.product_name,
            'weight': store.attribute_data.size ||  store.attribute_data.weight,
            'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
            "id" : store.cxs_product_id,
            "store_id": store.store_details.store_id,
            'price' : Number(store.attribute_data.price),
            'counter': 1
          })
          data.push({store})
          return data;
        }

        let store = getProduct(id, store_id, rawData) ;
        let isExists = false;
            for(let j=0;j<data.length;j++) {
              if(data[j].store.store_details.store_name === store.store_details.store_name) {
                if(data[j].store.names.indexOf(store.product_name) === -1) {
                  data[j].store.items.push({
                    'oprice': store.attribute_data.price,
                    'currency': store.attribute_data.currency,
                    'item': store.product_name,
                    'weight': store.attribute_data.size ||  store.attribute_data.weight,
                    'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
                    "id" : store.cxs_product_id,
                    "store_id": store.store_details.store_id,
                    'price' : Number(store.attribute_data.price),
                    'counter': 1
                  })
                  data[j].store.names.push(store.product_name)
                }
                isExists = true;
              } 
            }
  
        if(isExists === false) {
          let store = getProduct(id, store_id, rawData)
          store.items = [];
          store.names = [store.product_name]
          store.store_names = [store.store_name]
          store.items.push({
            'oprice': store.attribute_data.price,
            'currency': store.attribute_data.currency,
            'item': store.product_name,
            'weight': store.attribute_data.size ||  store.attribute_data.weight,
            'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
            'price' : Number(store.attribute_data.price),
            "id" : store.cxs_product_id,
            "store_id": store.store_details.store_id,
            'counter': 1
          })
          data.push({store})
        } 
        return data;
    }

    const addButton = (id, store_id) => (
      <TouchableOpacity onPress={() => setItems(true, id, store_id) }>
        <View style={styles.btn}>
          <Text style={styles.btnText}>Order Now</Text>
        </View> 
      </TouchableOpacity>
    );

    const popupwindow = (url, title, w, h) => {
      var left = (screen.width/2)-(w/2);
      var top = (screen.height/2)-(h/2);
      return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    }  

    const addViewButton = (element) => (
        <View style={{ width: 100, height: 30, backgroundColor: 'rgb(0, 89, 97)',  borderRadius: 10, padding:10 }}>
          <Text style={{textAlign: 'center', color: '#fff', fontSize:12}}>
            <a href={element['url']} target='_blank' style={{ color:'#fff', textDecoration:'none'}}> View Now </a>  
          </Text>
        </View>
    );
    
  const _addToRow = (element, key) => {
      const rows = [];
      for(let x in element) {
          if(!(x === 'id' || x === 'store_id' || x === 'url' || 
          x=== 'type' || x === 'lat' || x=== 'long' || 
          x === 'store_latitude' || x=== 'store_longitude'))
          if(x.indexOf('logo') === 0) {
            rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}> 
            <Image style={{
                width: 50,
                height: 40,
                resizeMode: 'contain'
            }} source={{ uri: (element[x])}} />
            </DataTable.Cell>)
          } else {
            rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}> 
            <Text style={{
                fontFamily: 'Barlow-Regular', fontSize:12
            }}>{ element[x] } </Text> </DataTable.Cell>);
        }
      }

      if(element['type'] === 'digital') {
        rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>{addViewButton(element)}</DataTable.Cell>);
        rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}></DataTable.Cell>);
      } else {
        rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>{addButton(element['id'], element['store_id'])}</DataTable.Cell>);
        rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}>
          <Text style={{ alignSelf: 'center' }} onPress={() => popupwindow('https://map.cxsphere.com/?store_latitude='+element['store_latitude']+'&store_longitude='+element['store_longitude']+'&lat='+element['lat']+'&long='+element['long'], 'cxsphere map', '400','400')}>
                          <EvilIcons name="location" style={{ fontSize:20 }} />
          </Text>
        </DataTable.Cell>);
      }      
      return rows;
  }

  const decideSort = (key, isortName) => {
      if(sortColumn === key) {
          setSortColumn(key)
          setSortType(sortType === 'ascending' ? 'decending' : 'ascending')
      } else {
          setSortType('ascending')
          setSortColumn(key)
      }
      setSortName(isortName.toLowerCase().replace(' ', '_'))
  }

  const sortByProperty = (property) => {
      if(sortType === 'ascending') { 
          return function(a,b){  
          if(a[property] > b[property])  
              return 1;  
          else if(a[property] < b[property])  
              return -1;  
          return 0;  
          }
      } else { 
          return function(a,b) {  
              if(a[property] < b[property])  
                  return 1;  
              else if(a[property] > b[property])  
                  return -1;  
              return 0;  
              }
      }  
    }

    const incrementItem = (id,store_id) => {
        let data = orders;
        for(let i=0;i<data.length;i++) {
            let storeItems = data[i].store.items;
            for(let j=0;j<storeItems.length;j++) {
                if(storeItems[j].id === id && storeItems[j].store_id === store_id ) {
                  storeItems[j].counter = Number(storeItems[j].counter) + 1;
                  storeItems[j].price = Number(storeItems[j].counter) * Number(storeItems[j].oprice);
                  storeItems[j].amount = storeItems[j].currency + ' ' + storeItems[j].price 
                }
            }
        }
        setItems(true,990, 10000);
    }

    const decrementItem = (id,store_id) => {
      let data = orders;
      for(let i=0;i<data.length;i++) {
          let storeItems = data[i].store.items;
          for(let j=0;j<storeItems.length;j++) {
              if(storeItems[j].id === id && storeItems[j].store_id === store_id) {
                if(storeItems[j].counter > 1) {
                  storeItems[j].counter = Number(storeItems[j].counter) - 1;
                  storeItems[j].price = Number(storeItems[j].counter) * Number(storeItems[j].oprice);
                  storeItems[j].amount = storeItems[j].currency + ' ' + storeItems[j].price
                } 
              }
          }
      }
      setItems(true,990,10000);
    }

    const subTotal = () => {
      let data = orders;
      let price = 0;
      let currency;
      for(let i=0;i<data.length;i++) {
          let storeItems = data[i].store.items;
          for(let j=0;j<storeItems.length;j++) {
                 price += storeItems[j].price;
                 currency = storeItems[j].currency;
          }
      }
      return currency + ' ' + price;
    }

    products.content && products.content.sort(sortByProperty(sortName))

    const handleText = (name, setValue) => {
      return (text) => {
        setValue(text);
      }
    }
  
    return (
        <>
      <View style={styles.centeredView}>
      { modalVisible && <Modal
        animationType="slide"
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <TouchableHighlight
              style={{ ...styles.openButton}}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}>
              <Text style={styles.textStyle}>X</Text>
            </TouchableHighlight>
            <View style={{marginTop:10}}>
            <Text style={{
                fontFamily: 'Barlow-Regular', 
                color: Color.DarkGreen,
                fontSize:18,
                fontWeight:'800'}}>Order details</Text>
                <View style={{flex:1, flexDirection:'row', marginTop:10, width:'100%'}}>
                      <View style={{ width:'30%',          
                                    backgroundColor:Color.DarkGreen, 
                                    padding:10 }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#fff'}}> Stores </Text>
                      </View>
                      <View style={{ width:'15%',          
                                    backgroundColor:Color.DarkGreen, 
                                    padding:10 }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#fff'}}> Item </Text>
                      </View>
                      <View style={{ width:'15%',          
                                    backgroundColor:Color.DarkGreen, 
                                    padding:10 }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#fff'}}> Size/Weight </Text>
                      </View>
                      <View style={{ width:'25%',          
                                    backgroundColor:Color.DarkGreen, 
                                    padding:10 }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#fff'}}> </Text>
                      </View>
                      <View style={{ width:'15%',          
                                    backgroundColor:Color.DarkGreen, 
                                    padding:10 }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#fff'}}> Amount </Text>
                      </View>
                </View>
                {
                  orders.map((item)=> { 
                  return (
                    <View style={{flex:1, flexDirection:'row', marginTop:10, paddingBottom:5, 
                    width:'100%', 
                    borderColor:'#fff',
                    borderBottomColor:'#000', 
                    borderWidth:1 }}>
                    <View style={{ width:'30%',padding:5 }}>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_name} </Text>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_address} </Text>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_contact} </Text>
                      </View>
                      <View style={{width:'70%'}}>
                      {
                        item.store.items.map(elem => {
                          return (
                            <View style={{flex:1, flexDirection:'row', marginTop:10, width:'100%'}}>
                              <View style={{ width:'25%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.item} </Text>
                              </View>  
                              <View style={{ width:'25%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.weight} </Text>
                              </View>    
                              <View style={{ width:'30%',paddingLeft:10, height:25 }}>
                                <View style={{ flex:1, borderRadius:7, flexDirection:'row', width: 50, backgroundColor: '#eee', alignItems:'center'}}>  
                                  <TouchableOpacity onPress={() => decrementItem(elem.id,elem.store_id) } style={{width:20, paddingLeft:4}}>
                                        <Text>-</Text>
                                  </TouchableOpacity>
                                        <Text style={{ fontFamily: 'Barlow-Regular',fontSize:12, color: Color.DarkGreen, alignSelf:'center' }}>{elem.counter}</Text>
                                  <TouchableOpacity onPress={() => incrementItem(elem.id, elem.store_id) } style={{width:20, alignItems:'flex-end'}}>
                                        <Text>+</Text>
                                  </TouchableOpacity>      
                                </View>
                              </View>    
                              <View style={{ width:'20%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.amount} </Text>
                              </View>    
                            </View>  
                          )
                        })
                      }
                      </View>  
                    </View>  
                  )})
                }
                <View style={{flex:1, flexDirection:'row', width:'100%', backgroundColor:'#eee' }}>
                      <View style={{ width:'76%',          
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'9%',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular',fontWeight:'600', 
                            fontSize:10, color:'#000'}}> Subtotal </Text>
                      </View>
                      <View style={{ width:'10%',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#000'}}> {subTotal()} </Text>
                      </View>
                </View>
                <View style={{flex:1, flexDirection:'row', width:'100%', backgroundColor:'#eee'}}>
                      <View style={{ width:'76%',          
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'9%',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular',fontWeight:'800', 
                            fontSize:10, color:'#000'}}> Total </Text>
                      </View>
                      <View style={{ width:'10%',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#000'}}> {subTotal()}  </Text>
                      </View>
                </View>
                <View style={{flex:1, flexDirection:'row', width:'100%', backgroundColor:'#eee' }}>
                      <View style={{ width:'67%',          
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'18%',          
                                    padding:10,backgroundColor:'#eee', alignItems:'flex-end' }}>
                          <Text style={{ 
                            fontFamily: 'Barlow-Regular', 
                            fontSize:10, color:'#000'}}> Coupon code if any </Text>
                      </View>
                      <View style={{ width:'10%',          
                                    backgroundColor:'#eee' }}>
                          <TextBox name='coupon_code' 
                            value={inputCouponCode} 
                            placeholder="Code" 
                            textstyle={{ fontFamily: 'Barlow-Regular', backgroundColor:'#fff', 
                            fontSize:10 }}
                            onChangeText={handleText("inputCouponCode", setInputCouponCode)}  />
                      </View>
                </View>
                <View style={{ marginTop: 10, flex:1, flexDirection:'row', width:'100%'}}>
                  <TouchableOpacity onPress={() => setisAdd2CartClick(!isAdd2CartClick) }>
                      <View style={styles.submitBtn}>
                        <Text style={styles.btnText}>Pre order now</Text>
                      </View> 
                  </TouchableOpacity>
                </View>           
            </View>
          </View>
        </View>
      </Modal>  }
      </View>
      <View style={styles.queryContainer}>
                            <View style={{alignSelf:'center'}}>
                                <Text style={styles.countText}> results : {products.count} </Text>
                            </View>

                            <View style={{paddingLeft:20, width:'14%', paddingRight: 10 }}>
                                <Picker
                                    style={styles.textDropDownStyle}
                                    selectedValue={searchHeader}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setSearchHeader(itemValue)
                                    }>
                                      <Picker.Item label={''} value={''} />
                                      <Picker.Item label={'Product Name'} value={'product_name'} /> 
                                      <Picker.Item label={'Generic Name'} value={'generic_name'} />
                                      <Picker.Item label={'Description'} value={'description'} />             
                                  </Picker>
                            </View>

                            <View>
                            <TextBox name='inputSearch' 
                            value={inputSearch}
                            textStyle={styles.inputBox} 
                            placeholder="Search" 
                            onChangeText={handleText("inputSearch", setInputSearch)}  />
                            </View>

                            <View>
                            <Button 
                            buttonStyle={{ width: 100, backgroundColor: Color.DarkGreen, alignSelf:'center', marginRight:20 }} 
                            onPress={() => {
                                submitSearch()
                            }}
                            title="Search">
                            </Button>
                            </View>

                            <View style={{
                                width:'100%',
                                flex:1,
                                flexDirection:'row'                                
                            }}
                            >
                            { start > 0 &&  
                            <Button buttonStyle={{ width: 100, backgroundColor: Color.DarkGreen, 
                            alignSelf:'center', marginRight:20 }} onClick={() => setStart(start - numberofRows)}
                            title="Prev">
                            </Button>
                            }

                            {products.count > (start + numberofRows) && 
                            <Button buttonStyle={{ width: 100, backgroundColor: Color.DarkGreen, alignSelf:'center', marginRight:20 }} 
                            onClick={() => setStart(start + numberofRows)}
                            title="Next">
                            </Button>
                            }

                            </View>
                        </View>
                        <View style={styles.container}> 
                          <ScrollView horizontal={false}>
                        <DataTable>
                        <DataTable.Header style={{ backgroundColor:'#eee'}}>
                         { products.header.map((item, key) => {
                            return ( <DataTable.Title 
                                sortDirection={sortColumn === key ? sortType : false }
                                onPress={() => decideSort(key, item)}    
                                style={{ justifyContent:'space-evenly'
                                }}>
                            <Text style={{
                                fontFamily: 'Barlow-Regular', 
                                color: Color.DarkGreen, 
                                fontWeight:'600'}} >{item} </Text>
                            </DataTable.Title>
                            )     
                         })} 
                        </DataTable.Header>

                        {products.content.map((element) => 
                            <DataTable.Row style={{backgroundColor:'#fff'}}>
                            {
                                _addToRow(element)
                            }
                            </DataTable.Row>   
                        )}
                       </DataTable>
                    </ScrollView>
                        </View>
      </>
    );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#eee', width:'100%' },
  head: { height: 50, backgroundColor: '#EEE', padding:10 },
  text: { fontSize:12, color: Color.DarkGreen, fontFamily: 'Barlow-Regular', 
  fontWeight:'600' },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
  rowText: {fontFamily: 'Barlow-Regular', fontSize:12 },
  btn: { width: 100, height: 30, backgroundColor: 'rgb(0, 89, 97)',  borderRadius: 10, padding:10 },
  submitBtn: { width: 100, height: 30, 
    backgroundColor: 'rgb(0, 89, 97)',  
    borderRadius: 10, padding:5 },
  btnText: { textAlign: 'center', color: '#fff', fontSize:12 },
  inputBox: {
    backgroundColor: Color.White,
    width: 400,
    marginRight: 20
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    zIndex:9999,
    position:'absolute',
    top:10,
    right:5
  },

  MenuContainerView: {
    flex: 1,
    alignSelf:'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex:9999,
    position:'absolute',
    top:Dimensions.get("window").height - 200,
    right:5
  },
  countText: { fontSize:12, color: Color.DarkGrey, fontFamily: 'Barlow-Regular', 
  fontWeight:'600', paddingLeft:18, paddingRight:18 },
  menuModalView: {
    width: Dimensions.get("window").width * 0.6,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalView: {
    width: Dimensions.get("window").width * 0.6,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    padding: 30
  },
  
  openButton: {
    backgroundColor: '#fff'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  queryContainer:{
    flexDirection:'row',
    padding:10,
    marginLeft: 40,
    backgroundColor: '#fff',
    margin: 5,
    marginRight: 45,
    borderRadius:5,
    width:'93.4%'
  },
  textDropDownStyle: {
    borderWidth: 1,
    borderColor: '#DEDEDE',
    width: '100%',
    borderRadius: 5,
    fontSize: 14,
    padding: 12,
    marginBottom: 12,
    fontFamily: 'Barlow-Regular'
  }
});