import React, { useEffect, useState, useContext } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import {  Text, View } from 'react-native';
import AuthenticatedLayout from './AuthenticatedLayout';
import { Image, Card, Button, Icon } from 'react-native-elements';
import { Color } from '../utils/color';
import config from '../config';
import { UserContext } from '../UserContext';
import {fetchFavoriteBrands, favoriteBrand, fetchBrandCollaboration} from '../services/SummaryService';
import {fetchLinkedSocial} from '../services/ProfileService';
import {Feather} from '@expo/vector-icons';
import {showConfirm, showMessage } from '../utils/UIUtil';

export default function SummaryScreen(props) {
    const userData = useContext(UserContext);
    return (
        <AuthenticatedLayout {...props} title="Summary">
            {originalWidth => (<SummaryContent {...userData.userData} {...props} />)}
        </AuthenticatedLayout>
    );
}
function SummaryContent(props) {
    const [linkedSocial, setLinkedSocial] = useState([]);
    const [favoriteBrands, setFavoriteBrands] = useState([]);
    useEffect( () => {
        fetchLinkedSocial(props).then(arg => setLinkedSocial(arg.linkedSocialList))
        }, []);
    return (
        <View style={[{ flexDirection: 'column' }]}>
            <BrandCollaboration {...props} setFavoriteBrands={setFavoriteBrands} />
            <FavoriteBrands {...props} setFavoriteBrands={setFavoriteBrands} favoriteBrands={favoriteBrands}/>
        </View>
    );
}

function BrandCollaboration(props) {
    const [brandCollaboration, setBrandCollaboration] = useState([]);

    const handleFavBrand = (accessToken, source, sourceId) => {
        favoriteBrand(accessToken, source, sourceId).then(res => {
            showMessage("Success", "You have favorited a brand ");
            fetchFavoriteBrands(accessToken).then(res => props.setFavoriteBrands(res));
        }).catch(error => showMessage("Error", "Error while making post redemption call."));
    }

    useEffect( () => {
        fetchBrandCollaboration(props).then(arg => setBrandCollaboration(arg))
        }, []);
    return (
        <View style={[{ flexDirection: 'column', 'marginTop':20 }]}>
            <Text style={{ fontSize: 24, fontWeight: 'bold' }}>Brand Collaboration</Text>
            <View style={[{flexDirection:'row', flexWrap:'wrap', paddingTop:10}]}>
                {brandCollaboration.map((brand, index) => {
                    return (
                        <Card containerStyle={{ width: 300, margin:5 }} key={index}>
                            <Text style={{ alignSelf:'center' }}>{brand.name}</Text>
                            <Image style={{ height: 120, width: 300, }}
                                resizeMode='contain'
                                accessibilityLabel = {brand.name}
                                source={brand.source === 'catalog' ? { uri: config.apiurl +  "/static/" + brand.brandPicture } : {uri:brand.brandPicture}} />
                            <Button
                                icon={<Feather name="thumbs-up" size={32} />}
                                buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                                accessibilityRole="button"
                                accessibilityLabel="Favorite"
                                onPress = {() => handleFavBrand(props.accessToken, brand.source, brand.sourceId)}
                             />
                        </Card>

                    );
                })}
            </View>
        </View>
    );
}
function FavoriteBrands(props) {
    
    useEffect(() => {
        fetchFavoriteBrands(props.accessToken).then(res => props.setFavoriteBrands(res))
    }, []);
    return (
        <View style={[{ flexDirection: 'column', 'marginTop':30 }]}>
            <Text style={{ fontSize: 24, fontWeight: 'bold' }}>Favorite brands</Text>
            <View style={[{flexDirection:'row',flexWrap:'wrap'}]}>
                {props.favoriteBrands.map((brand, index) => {
                    return (
                        <Card containerStyle={{ width: 380 }} key={index}>
                            <Text style={{ alignSelf:'center'}}>{brand.name}</Text>
                            <Image style={{ height: 190, width: 380, }}
                                resizeMode='contain'
                                source={brand.source === 'catalog' ? { uri: config.apiurl +  "/static/" + brand.brandPicture } : {uri:brand.brandPicture}} 
                                accessibilityLabel={brand.name} />
                        </Card>
                    );
                })}
            </View>
        </View>
    );
}