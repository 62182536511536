import * as Linking from 'expo-linking';
import config from '../config';
import * as AuthSession from 'expo-auth-session';
import {GMAIL_REDIRECT, FACEBOOK_REDIRECT } from 'react-native-dotenv';
import { makeFetchCall } from './BaseService';
import { Alert } from 'react-native';

export function googleSignIn() {
    Linking.openURL("https://accounts.google.com/o/oauth2/auth?client_id=434040208087-dj58pcbi5tkmroo655c9sis9h6n6pqaq.apps.googleusercontent.com&access_type=offline&response_type=code&redirect_uri=https://mycxid.com/LoginCallback&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/profile.language.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/contacts.readonly");
}

export function facebookSignIn() {
   Linking.openURL("https://www.facebook.com/v10.0/dialog/oauth?client_id=759831194979549&redirect_uri=https://mycxid.com/LoginCallback&state=test&scope=email");
}

export function googleProfileSignIn(props) {
    Linking.openURL(`https://mycxapi.cxsphere.com/google-data?user_id=${props.user_id}&at=${props.accessToken}`);
}

export function facebookProfileSignIn(props) {
    Linking.openURL(`https://mycxapi.cxsphere.com/facebook-data?user_id=${props.user_id}&at=${props.accessToken}`);
}

export async function processGoogleAuthorizationCode(code, platform, social_source, userToken) {

	let api_url = "/google-code/";
	if (social_source === 'facebook'){
		api_url = "/facebook-code/";
	}

    console.log('step to debug api call');
    console.log(config.apiurl + api_url + code + "?platform=" + platform, userToken);

    try 
	{
        if (userToken !== 0){
            let response = await makeFetchCall(config.apiurl + api_url + code + "?platform=" + platform, userToken);
            let responseJSON = await response.json();
            console.log("Response received: " + JSON.stringify(responseJSON));
            return responseJSON;

        } else {
            console.log("fetching" + config.apiurl + api_url + code + "?platform=" + platform);
  		    let response = await fetch(config.apiurl + api_url + code + "?platform=" + platform);
            let responseJSON = await response.json();
            console.log("Response received: " + JSON.stringify(responseJSON));
            return responseJSON;
        }
    }catch(error) {
        console.log("Error while authorizing: " + error);
        return {'status': 'Error'};
    }
}
