import React from 'react';
import AsyncStorage  from '@react-native-async-storage/async-storage'
import { Text, View, TouchableOpacity, Button, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import {renderers, Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import { EvilIcons } from '@expo/vector-icons';
import { Color } from '../utils/color';
import {acquireToken} from '../services/TokenAcquirer';

const askForNotificationPermission = async() => {
     if(Notification.permission === 'default') {
         acquireToken();
 }
}
export default function CXSphereRightMenu(props) {
    const handleLogout = () => {
        AsyncStorage.clear();
        props.navigation.navigate("Login");
    }
    const handleSettings = () => {
        props.navigation.navigate("Settings");
    }
    return (
        
        <View >
            <Menu renderer={renderers.Popover} rendererProps={{placement: 'bottom'}} >
                <MenuTrigger accessibilityLabel="Options" accessibilityRole="button">
                    <Ionicons name="md-menu" size={38} />
                </MenuTrigger>
                <MenuOptions customStyles={menuOptionStyle}>
                    {Platform.OS === 'web' && <MenuOption onSelect={askForNotificationPermission}>
                        <Text style={{alignSelf:'center'}}>Enable desktop notifications</Text>
                    </MenuOption>}
                    <MenuOption onSelect={handleSettings} >
                        <Text style={{ alignSelf: 'center' }}>
                            <EvilIcons name="gear" style={{ margin: 5 }} />
                            Settings
                        </Text>
                    </MenuOption>
                    <MenuOption onSelect={handleLogout} >
                        <Text style={{ alignSelf: 'center' }}>
                            <EvilIcons name="clock" style={{ margin: 5 }} />
                            Logout
                        </Text>
                    </MenuOption>
                </MenuOptions>
            </Menu>
            {/* 
                <Ionicons name="md-menu" size={32} />
            </TouchableOpacity> */}
        </View>
    );
}
const menuOptionStyle = {
    optionsContainer: {
        width:150,
    },
    optionTouchable: {
        justifyContent: 'center',
    }
}