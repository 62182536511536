import React, { useState, useEffect, useContext } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { FlatList, SafeAreaView, TouchableOpacity, Text, View, Image } from 'react-native';
import { Button} from 'react-native-elements';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AuthenticatedLayout from './AuthenticatedLayout';
import Label from '../components/Label';
import { Color } from '../utils/color';
import {markAsRead, fetchNotifications } from '../services/NotificationService';
var moment = require('moment');
import { UserContext } from '../UserContext';

export default function NotificationScreen(props) {

    const userData = useContext(UserContext);
    const [notificationData, setNotificationData] = useState([]);
    const [renderNotifications, setRenderNotifications] = useState(true);
    useEffect(() => {
        if(renderNotifications === true) {
            callNotifications();
        }
    }, [renderNotifications]);

    const callNotifications = () => {
        fetchNotifications(userData.userData.accessToken).then(res => {
            setNotificationData(res);
            setRenderNotifications(false);
        }).catch(error => console.log("Error while fetching notifications. " + error));
    }

    return (
        <AuthenticatedLayout {...props} title="Notifications">
            {originalWidth => (<NotificationContent {...props} setRenderNotifications={setRenderNotifications} notifications={notificationData} originalWidth={originalWidth} {...userData.userData} />)}
        </AuthenticatedLayout>
    );
}

function NotificationContent(props) {
    const [showNotificationDetails, setShowNotificationDetails] = useState(props.originalWidth> 767);
    const _handleShowNotificationDetails = () => {
        console.log("Inside handleShowNotificationDetails and value of flag: " + showNotificationDetails);
        setShowNotificationDetails(!showNotificationDetails);
    }
    const [selectedNotification, setSelectedNotification] = useState(props.notifications.length > 0 ? props.notifications[0] : null);
    return (
        <View style={[BaseStyle.container], { flex: 6, flexDirection: 'row' }}>
            {(props.originalWidth >767 || !showNotificationDetails) && <NotificationList {...props} handleShowNotificationDetails={_handleShowNotificationDetails} setSelectedNotification={setSelectedNotification} />}
        </View>
    );
}
function NotificationList(props) {
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                showsVerticalScrollIndicator={true}
                data={props.notifications}
                renderItem={({ item }) => <NotificationItem notification={item} {...props} />} />
        </View>
    );
}
function NotificationItem(props) {
    return (
        <TouchableOpacity
            style={[BaseStyle.notificationListItem]}
            onPress={() => {
                console.log("Before setting selected notification and notification title: " + props.notification.title);
                markAsRead(props.accessToken, props.notification.id);
                props.setSelectedNotification(props.notification);
                console.log("Before handleShowNotificationDetails.");
                props.handleShowNotificationDetails();
                props.setRenderNotifications(true);
            }}>
            <View style={BaseStyle.SocialMediaNotification}>
                <Image style={{ height: 19, width: 19, }} source={require("./../assets/images/instagram.png")} />
                <Label Barlow_Regular color={Color.BlackLight}>{props.notification.title}</Label>
                <Label font11Small Barlow_Regular color={Color.DarkGreen} >{moment(props.notification.posted_at).format('YYYY-MM-DD hh:mm').toString()}</Label>
                <View style={[BaseStyle.dotted, { backgroundColor: (props.notification.read == false ? 'red' : Color.DarkGreen) }]}></View>
            </View>
            <View style={{paddingLeft: 50}}>
            <Label Barlow_Regular x12Small color={Color.LightGrey}>{props.notification.text}</Label>
            </View>
        </TouchableOpacity>
    );
}
function NotificationDetails(props) {
    if (!props.selectedNotification) {
        return null;
    }
    return (
        <View style={{ flex: 1 }}>
            {props.originalWidth < 767 &&  <Button
                style={{ alignSelf: "flex-end", marginRight: 20, marginTop: 15, }} accessibilityRole="button"
                onPress={props.handleShowNotificationDetails} title="Cancel">
                <Label font15Large color={Color.DarkGreen}>Cancel</Label>
            </Button>}
            <KeyboardAwareScrollView>
                <View style={[{backgroundColor:Color.White, margin: 20, padding: 15, }]}>
                    <Label Barlow_Regular font15Large color={Color.LightGrey} style={BaseStyle.SubTitle}>{moment(props.selectedNotification.posted_at).format('YYYY-MM-DD hh:mm').toString()}</Label>
                    <Label Barlow_Medium style={BaseStyle.SocialsMediaTitle}>{props.selectedNotification.title}</Label>
                    <Label Barlow_Regular font15Large color={Color.LightGrey} style={BaseStyle.SocialsMediaContain}>
                        {props.selectedNotification.text}
                    </Label>
                </View>
            </KeyboardAwareScrollView>
        </View>
    );
}