import firebase from 'firebase/app';
import '@firebase/messaging';
import AsyncStorage from '@react-native-async-storage/async-storage'
import {postNotificationToken} from './ProfileService';

const config = {
    apiKey: "AIzaSyDC2Go7xK-S--DPft7Xq_oTDUHViikFBJw",
    authDomain: "test-push-cxsphere.firebaseapp.com",
    databaseURL: "https://test-push-cxsphere.firebaseio.com",
    projectId: "test-push-cxsphere",
    storageBucket: "test-push-cxsphere.appspot.com",
    messagingSenderId: "954325552403",
    appId: "1:954325552403:web:e027a9534afaf9c61adee6"
};

firebase.initializeApp(config);

let messaging;

// we need to check if messaging is supported by the browser
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

// register service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            updateViaCache: 'none'
        });
        messaging.useServiceWorker(registration);
        console.log("Registered service worker.");
        await getTokenIfPermitted();
        messaging.onMessage((payload) => {
            console.log("Received message: ", payload);
            //registration.showNotification("Title", {});
        });
    });
}

const getTokenIfPermitted = async () => {
    console.log("User permission status: ", Notification.permission);
    if (Notification.permission !== 'granted') {
        return;
    }
    acquireToken();
}
export const acquireToken = async () => {
    try {
        const token = await messaging.getToken();
        console.log("Got the token: ", token);
        const userNotificationStatusKey = "notificationStatus";
        let notificationStatus = await AsyncStorage.getItem(userNotificationStatusKey);
        if (!notificationStatus) {
            notificationStatus = { 'permission': 'granted', 'token': token };
            await AsyncStorage.setItem(userNotificationStatusKey, JSON.stringify(notificationStatus));
        } else {
            console.log("Got notificationStatus from local storage: " + notificationStatus);
            let status = JSON.parse(notificationStatus);
            status['token'] = token;
            await AsyncStorage.setItem(userNotificationStatusKey, JSON.stringify(status));
            const user = JSON.parse(await AsyncStorage.getItem('user'));
            if(user) {
                const accessToken = user.accessToken;
                await postNotificationToken('firebase', token, accessToken);
            }
            
        }
    } catch (err) {
        console.log("Error while getting user permission/token. ", err);
        if (err.hasOwnProperty('code') && err.code === 'messaging/permission-default') console.log('You need to allow the site to send notifications');
        else if (err.hasOwnProperty('code') && err.code === 'messaging/permission-blocked') console.log('Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings');
        else console.log('Unable to subscribe you to notifications');
    } finally {
    }
}
export {
    messaging
}