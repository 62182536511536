import React, { useState, useEffect} from 'react';
import { Platform } from 'react-native';
import { View, TouchableOpacity, Text, Alert} from 'react-native';
import { PersonalInformationSubHeader } from '../components/PersonalInformationSubHeader';
import { EvilIcons } from '@expo/vector-icons';

export default function SettingsContent(props) {
    const [share, setShare] = useState([]);
    const [refresh, setrefresh] = useState(true);
  
    const fetchSocialShare = async (props) => {
        let response = await fetch('https://mycxapi.cxsphere.com/user/data?at=' + props.accessToken, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Bearer ' + props.accessToken
          },
      });
      let responseJson = await response.json();
      let sourceArray = responseJson; 
      if(!sourceArray.message) {
          return sourceArray;
      } else { 
          return [];
      }
  }

  const setDeleteAccount = async (item, primary_id) => {
    let response = await fetch('https://mycxapi.cxsphere.com/user/data?at=' + props.accessToken, {
      method: 'DELETE',
      body: JSON.stringify({
        source: item, 
        account_id: primary_id
      }),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.accessToken
      },
  });
  let responseJson = await response.json();
  let sourceArray = responseJson; 
  console.log("Finished delete profiles.");
  console.log(sourceArray);
  setrefresh(true);
}

  
  useEffect( () => {
      if(refresh === true) {
        fetchSocialShare(props)
          .then(arg => {
              setShare(arg);
              setrefresh(false);
            })
      }
    }, [refresh]);

    const ConfirmationDialog = (source, account_id) => {
    const returnYes = window.confirm('Are you sure, Do you want to remove. It reduce your profile engagement  ?')
        if(returnYes === true) {
            setDeleteAccount(source,account_id) 
        }
    }

    return (
        <React.Fragment>
        { Platform.OS !== 'web' && ( 
        <View style={{flexDirection:'row'}}>
            <View>
            <PersonalInformationSubHeader title={'Social Shared Accounts'}>
            { share && share.map((identity,i) => {
                return  (
                <View style={{flexDirection:'row'}} key={i}>
                    <Text style={{paddingLeft:'10px', paddingTop:'5px'}}>{identity.account_name}</Text>
                    <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => setDeleteAccount(identity.source,identity.account_id)}>
                                <Text style={[{ fontSize: 20, fontWeight: 'bold', paddingLeft:'5px' }]}><EvilIcons name="close"/></Text>
                    </TouchableOpacity>
                </View>
                )})    
            }  
            </PersonalInformationSubHeader>    
            </View>
        </View>
        )}
        { Platform.OS === 'web' && ( 
        <View style={{flexDirection:'row'}}>
            <View style={{  width:'30%'}}>
            <PersonalInformationSubHeader title={'Social Shared Accounts'}>
            { share.map((identity) => {
                return  (
                <View style={{flexDirection:'row'}}>
                    <Text style={{paddingLeft:'10px', paddingTop:'5px'}}>{identity.account_name}</Text>
                    <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => ConfirmationDialog(identity.source,identity.account_id)}>
                                <Text style={[{ fontSize: 20, fontWeight: 'bold', paddingLeft:'5px' }]}><EvilIcons name="close"/></Text>
                    </TouchableOpacity>
                </View>
                )})    
            }  
            </PersonalInformationSubHeader>    
            </View>
        </View>
        )}
        </React.Fragment>
    );
}
