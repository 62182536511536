import React, { useState, useContext } from 'react';
import styles from '../stylesheets/LoginStyle';
import {
  View,
  SafeAreaView,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator,
  Linking
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import Label from '../components/Label';
import TextBox from '../components/textbox';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { validateCharacter, validateEmail, validName, validatePassword, isSamePassword, occupationName } from '../utils/validation';
import SocialSignIn from '../components/SocialSignIn';
import config from '../config.js';
import useNativeLayout from '../hooks/NativeLayoutHook';
import {UserContext} from '../UserContext';

export default function LoginScreen(props) {

      const [username, setUsername] = useState("");
      const [password, setPassword] = useState ("");
      const [isLoadingSignIn, setIsLoadingSignIn] = useState(false);
      const userData = useContext(UserContext);
      const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();
      
      const setOrganizationData = async () => {
          if(Platform.OS === 'web') {
            let url = window.location.hash;
            url = url.replace('#38;','&');
            url =  window.location.search + url;
            const params = new URLSearchParams(url);
            const org_id = params.get('org_id');
            const org_name = params.get('org_name');
            if(org_id) {
              console.log('Registering Organization');
              await AsyncStorage.setItem('Organization', JSON.stringify({
                org_id,org_name
              }));
            }
          }
      }
      
      setOrganizationData();

  const handleText = (name, setValue) => {
    return (text) => setValue(text.replace(/\s/g, ''))
  }

  const loginValidation = () => {

    var message = "";
    if (username.length == 0) {
      message = "Emailid can not be empty."
    } else if ((!validateEmail(username))) {
      message = "Please enter a valid email address"
    } else if (password == 0) {
      message = "Please enter password"
    } else if (password.length < 8) {
      message = "Password length must be between 8 and 128"
    }

    return message;
  }

  const loginAccount = async () => {

    let messageAlert = loginValidation()

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        alert(alertText)
      } else {
        Alert.alert(alertTitle, alertText)
      }
      return;
    }
    setIsLoadingSignIn(true);

    try {
      let response = await fetch(config.apiurl + '/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": username,
          "password": password
        }),
      });
      let responseJson = await response.json();
      console.log("====== responseJson =====", responseJson);
      setIsLoadingSignIn(false)

      if (typeof (responseJson.access_token) != "undefined") {
        const user = {'accessToken': responseJson.access_token, 
        'fullName': responseJson.fullName, 
        'user_id': responseJson.user_id,
        'email': username };
        await AsyncStorage.setItem('user', JSON.stringify(user));
        userData.setUserData(user);
        const fromProduct = await AsyncStorage.getItem('fromProduct');
        if(fromProduct === "true") {
          props.navigation.navigate("Product");
        } else {
          let organization = await AsyncStorage.getItem('Organization');
          organization = organization ? JSON.parse(organization) : {};
          if(organization.org_id) { 
            props.navigation.navigate("Organization")
          } else {
            props.navigation.navigate("Dashboard");
          }
        }
      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      setIsLoadingSignIn(false);
      console.error("====== error =====" + error);
    }
  }

    return (
      <SafeAreaView onLayout={_handleLayout} style={{ flex: 1 }}>
        <View style={[styles.Container, { backgroundColor: originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "center"
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <KeyboardAwareScrollView
            enableOnAndroid={true} extraHeight={50} extraScrollHeight={50}
            innerRef={ref => {
              scroll = ref
            }}>

            <View style={{
              flexDirection: 'row',
              alignItems: originalWidth >= 991 ? 'center' : 'flex-start',
              justifyContent: 'center',
              paddingTop: originalWidth >= 991 ? 0 : 20,
              height: (Platform.OS == 'android' ? Dimensions.get("window").height - 40 : Dimensions.get("window").height - 120),
              width: '100%',
            }}>

              <View style={styles.login}>
                <Label Barlow_SemiBold style={[styles.title, { display: (originalWidth < 991 ? 'none' : 'flex'), alignSelf: "center" }]}>Login</Label>
                <TextBox
                  textStyle={styles.inputBox}
                  value={username}
                  onChangeText={handleText("username", setUsername)}
                  placeholder="Email" />
                <TextBox
                  textStyle={styles.inputBox}
                  value={password}
                  onChangeText={handleText("password", setPassword)}
                  secureTextEntry={true}
                  onSubmitEditing={() => {
                    loginAccount()
                  }}
                  placeholder="Password" />
                <TouchableOpacity
                  style={{ width: 130, alignSelf: "flex-end" }}
                  onPress={() => {
                    props.navigation.navigate("ForgotPassword")
                  }}  >
                  <Label font14Normal style={styles.forgotPasswordText}> Forgot Password ?</Label>
                </TouchableOpacity>
                <TouchableOpacity onPress={loginAccount} style={styles.button} accessibilityRole="button">
                  {!isLoadingSignIn && <Label style={styles.btnText}>Sign in</Label>}
                  {isLoadingSignIn && <ActivityIndicator size="small" color="white" />}
                </TouchableOpacity>
                <View font14Normal Barlow_SemiBold style={[styles.loginOrLineMain, {
                  marginTop: originalWidth < 991 ? 35 : 135,
                  marginBottom: originalWidth < 991 ? 16 : 16,
                }]}>
                  <View style={styles.borderLine}></View>
                  <Label font14Normal Barlow_Regular style={styles.textOr}>Or</Label>
                  <View style={styles.borderLine}></View>
                </View>
                <SocialSignIn 
                isProfile = {false}
                buttonStyle={styles.button}
                iconImgStyle={styles.iconImg}
                btnTextStyle={styles.btnText}
                iconImgSecondStyle={styles.iconImgSecond} 
				navigate={props.navigation.navigate}/>
                <View style={[styles.textAccountMain, {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }]}>
                  <Text style={styles.textAccount}>You don't have an account?</Text>
                  <TouchableOpacity onPress={() => {
                    props.navigation.navigate("Registration");
                  }}><Text style={{ fontSize: 14, color: '#005961', marginLeft: 2, }}>Sign Up</Text></TouchableOpacity>
                </View>
              </View>
            </View>

          </KeyboardAwareScrollView>


        </View>
      </SafeAreaView >
    );

}

LoginScreen.navigationOptions = {
  header: null,
};
