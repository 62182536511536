import React, { useState, useEffect } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import styles from '../stylesheets/LoginStyle';
import { Image, TouchableOpacity, Text, View, Platform, Button} from 'react-native';
import { FontAwesome5, EvilIcons } from '@expo/vector-icons';
// import {SocialMediaIconsReact} from 'social-media-icons-react';
import SocialProfileSignIn from '../components/SocialProfileSignIn';
import { Color } from '../utils/color';
import Label from './Label';
import { Header, Card } from 'react-native-elements';
import {ProfileSocialShare} from './ProfileSocialShare';
import {CommercePlatform} from './CommercePlatform';
import {fetchBrandValue, fetchLinkedSocial} from '../services/ProfileService';
import { googleProfileSignIn, facebookProfileSignIn } from '../services/SocialSignInService';
import * as Linking from 'expo-linking';
import * as AuthSession from 'expo-auth-session';
import {RevenuePotential} from './RevenuePotential';
import UploadFile from '../components/UploadFile';
import { ButtonGroup } from 'react-native-elements'

function handleSocialNetwork(itemValue, props, setDisplaySocialNetworks){
    if(itemValue === 'facebook'){
        facebook(props);
    }
    if(itemValue === 'google'){
        google(props);
    }
    if(itemValue === 'linkedin'){
        props.navigation.navigate("UploadFile");
        setDisplaySocialNetworks(false);
    }
}

async function google(props) {
    if(Platform.OS === "web" ) {
        googleProfileSignIn(props);
        return;
    }
    //expo user @sudiptacx
    let googleUrl = "https://accounts.google.com/o/oauth2/auth?";
    googleUrl = googleUrl + encodeURIComponent("client_id") +"=";
    googleUrl = googleUrl + encodeURIComponent("430311999056-5pv5mmo80288fk7kv850hv8ok17ls0il.apps.googleusercontent.com") + "&";
    googleUrl = googleUrl + encodeURIComponent("access_type") + "=offline&";
    googleUrl = googleUrl + encodeURIComponent("response_type") + "=code&";
    googleUrl = googleUrl + encodeURIComponent("redirect_uri") + "=";
    googleUrl = googleUrl + encodeURIComponent("https://auth.expo.io/@cullsin/cxsphere-individual-app") + "&scope=";
    googleUrl = googleUrl + encodeURIComponent("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile");
    let response = await AuthSession.startAsync({"authUrl": googleUrl});
	console.log(response);
	if (response.type === 'success') {
      console.log(props);
	  props.navigate("Dashboard", response.params);
    }
}

async function facebook(props) {
    if(Platform.OS === "web" ) {
        facebookProfileSignIn(props);
        return;
    }
	
    let fbUrl = "https://www.facebook.com/v5.0/dialog/oauth?state=loggedin";
    let returl = Linking.makeUrl();
    fbUrl = fbUrl + encodeURIComponent("client_id") +"=";
    fbUrl = fbUrl + encodeURIComponent("759831194979549") + "&";
    fbUrl = fbUrl + encodeURIComponent("response_type") + "=code&";
    fbUrl = fbUrl + encodeURIComponent("redirect_uri") + "=";
    fbUrl = fbUrl + encodeURIComponent("https://auth.expo.io/@cullsin/cxsphere-individual-app") + "&scope=email";
    let response = await AuthSession.startAsync({"authUrl": fbUrl});
	console.log(response);
	if (response.type === 'success') {
	  props.navigate("Dashboard", response.params);
    }
}

export default function ProfileContent(props) {
    const [showRevenuePotential, toggleRevenuePotential] = useState(false);
    const [showCommercePlatform, setCommercePlatform] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false);
    const [inProgress, setInprogress] = useState(false);
    const [socialName, setSocialName] = useState();
    const downloadUrls = {
        facebook: 'https://www.facebook.com/dyi/',
        google: 'https://takeout.google.com/'
    };
    
    const proifileButtons = [
        <Text style={{ alignSelf: 'center' }}>
                            <FontAwesome5 name="plus" style={{ margin: 5 }} size='20' />
                            Add
                        </Text>, 
         <Text style={{ alignSelf: 'center' }}>
         <FontAwesome5 name="bolt" style={{ margin: 5 }} size='20' />
            Update
        </Text>, 
         <Text style={{ alignSelf: 'center' }}>
         <FontAwesome5 name="upload" style={{ margin: 5 }} size='20' />
            Upload
        </Text>];

    const MainButtons = [proifileButtons[2]];
    const [MainSelectedIndex, setMainSelectedIndex] = useState(2);
    if (showRevenuePotential) {
        return (
            <RevenuePotential toggleRevenuePotential={toggleRevenuePotential} {...props} />
        );
    }

    return (
        <View style={{flexDirection:'column'}}>
            <ProfileOptions {...props}  toggleRevenuePotential={toggleRevenuePotential} />
            <View style={{flexDirection: props.originalWidth > 767 ? 'row' : 'column'}}>
                <View style={{width: props.originalWidth < 767 ? '100%' : '30%'}}>
                    {props.originalWidth<=767 &&  <LinkedSocialMobile {...props} />}
                    <PersonalProfile {...props} />
                </View>
                { showInstruction === false && <View style={{ width: '70%'}}>
                <View style={{flexDirection:'row', marginBottom:20, minHeight: 200}}> 
                { !showCommercePlatform && <Card containerStyle={{ width:'46%', marginLeft: 0, backgroundColor: '#EEE'}}>
                        <Card.Title> Social Platform </Card.Title>
                        <Card.Title style={{textAlign:'left', paddingLeft: 7}}> Choose an Action </Card.Title>
                        <ButtonGroup
                            onPress={() => setMainSelectedIndex(2)}
                            selectedIndex={MainSelectedIndex}
                            buttons={MainButtons}
                            containerStyle={{height: 30}}
                        />   
                        <LinkedSocial {...props} 
                            setSocialName={setSocialName} 
                            MainSelectedIndex={MainSelectedIndex} 
                            setShowInstruction={setShowInstruction} /> 
                    </Card> }
                    { showCommercePlatform && 
                    <Card 
                    containerStyle={{ width:'46%', marginLeft: 0, 
                    backgroundColor: '#EEE',                             
                    marginRight:0, 
                    justifyContent:'center',
                    alignItems:'center',
                    flex:1}}>
                        <Card.Title style={{backgroundColor:'#185b61', color: '#fff', padding: 5}}> Commerce Platform </Card.Title>
                        </Card> }
                    <Card 
                        containerStyle={{ 
                            width:'46%', 
                            marginRight:0, 
                            justifyContent:'center',
                            alignItems:'center',
                            flex:1}}>
                            <Button color='#185b61' 
                            title={showCommercePlatform ? 'Social Platform' : 'Commerce Platform'}
                            onPress={() => setCommercePlatform(!showCommercePlatform)}  />
                    </Card>    
                </View>
                { !showCommercePlatform && <ProfileSocialShare {...props} /> }
                { showCommercePlatform && <CommercePlatform {...props} /> }
                </View> }
                { showInstruction === true && <View style={{ width: '70%'}}>
                    <Card>
                        <Card.Title>Instructions on How to Integrate your {socialName}</Card.Title>
                        <Card.Title style={{textAlign:'left'}}> Step 1: Download </Card.Title>
                        <View style={{'flexDirection':'row', marginBottom:15}}>
                        <Text>GoTo </Text>
                        <Text style={{color: 'blue', textDecorationStyle:'solid', textDecorationLine:'underline'}}
                            onPress={() => Linking.openURL(downloadUrls[socialName])}>
                            {socialName} 
                        </Text>       

                        <Text> link and download your content in zip format </Text>
                        </View>
                        <Card.Title style={{textAlign:'left'}}> Step 2: Upload </Card.Title>
                        <View style={{'flexDirection':'row'}}>
                        <Text> Upload the zip file below for Integration </Text>
                        </View>
                        <View style={{'flexDirection':'row'}}>
                            <UploadFile selected={socialName} 
                            {...props} 
                            setShowInstruction={setShowInstruction} 
                            setInprogress={setInprogress} />
                            <Text style={{color: 'red', 
                            paddingLeft:10, 
                            paddingTop: 20, 
                            textDecorationStyle:'solid', textDecorationLine:'underline'}}
                            onPress={() => setShowInstruction(false)}>
                                Back to Social Platform
                        </Text>       
                        </View>
                        { inProgress && <View style={{'flexDirection':'row'}}>
                            <Text style={{ paddingLeft:10, paddingTop: 20 }}>
                                Upload is in Progress...
                            </Text>       
                        </View> }
                    </Card>
                 </View> }   
            </View> 
        </View>
    );
}
const ProfileOptions = (props) => {
    const [brandValue, setBrandValue] = useState({requestCount: 0, revenuePotential: 0});
    useEffect( () => {
        fetchBrandValue(props).then(arg => setBrandValue(arg))
        }, []);
    return (
        // <View style={[BaseStyle.container, { flex: 1, backgroundColor:Color.WhiteLight, paddingBottom:10 }]}>
        <View style={[ {backgroundColor:Color.WhiteLight, paddingBottom:5, flexDirection:'row', margin:5 }]}>
            <TouchableOpacity
                style={BaseStyle.button}
                accessibilityRole="button"
                onPress={()=>props.navigation.navigate("Rewards")}>
                <Text style={BaseStyle.buttonText}>Profile Request</Text>
    <Text style={[BaseStyle.buttonText, {fontWeight:'bold'}]}>{brandValue.requestCount}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={BaseStyle.button} accessibilityRole="button" onPress={() => props.toggleRevenuePotential(true)}>
                <Text style={BaseStyle.buttonText}>Revenue Potential</Text>
                <Text style={[BaseStyle.buttonText, {fontWeight:'bold'}]}>{brandValue.revenuePotential}</Text>
            </TouchableOpacity>
        </View>
    );
}
const LinkedSocialMobile = (props) => {
    return (
        <Header containerStyle={{backgroundColor:Color.White}} leftContainerStyle={{flex:3}}>
            <Label Barlow_Medium color={Color.BlackLight} style={{ fontSize:20 }} >Linked Social</Label>
            <></>
            <TouchableOpacity style={[BaseStyle.smallButton]}>
                <Text style={BaseStyle.buttonText}>Update</Text>
            </TouchableOpacity>
            </Header>
    );
}
const LinkedSocial = (props) => {
    const buttons = [
        <>
        <View style={{marginTop: 4}}>
        {/* <SocialMediaIconsReact 
                borderColor="rgb(32, 137, 220)" 
                icon="facebook"
                url={() => void(0)} 
                iconColor="rgba(255,255,255,1)" 
                backgroundColor="rgb(32, 137, 220)"  
                size="17" /></View>
                <Text style={{ textAlignVertical:'super', paddingLeft:5 }}>
                            Facebook
                </Text></>,
            <>
            <View style={{marginTop: 4}}>
            <SocialMediaIconsReact 
                    borderColor="#de5246" 
                    icon="googleplus" 
                    iconColor="rgba(255,255,255,1)" 
                    backgroundColor="#de5246"  
                    url={() => void(0)}
                    size="17" /> */}
            </View>
            <Text style={{ textAlignVertical:'super',  paddingLeft:5 }}>
                                Google
                </Text></>];
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
            <>
            <View style={{ marginTop: 10}}>
            <Card.Title style={{textAlign:'left', paddingLeft: 7}}> Select Profile </Card.Title>    
            <ButtonGroup
                onPress={setSelectedIndex}
                selectedIndex={selectedIndex}
                buttons={buttons}
                containerStyle={{height: 30}}
            />
            </View>
            <View style={{ paddingLeft: 11, paddingRight: 11, marginTop: 10}}>
            { props.MainSelectedIndex === 0 && 
                <Button color='#185b61' 
                title="Add Profile" 
                onPress={() => selectedIndex === 0 ? facebookProfileSignIn(props) : googleProfileSignIn(props)} />   
            }
            { props.MainSelectedIndex === 1 && 
                <Button color='#185b61' 
                    title="Update Profile"
                    onPress={() => selectedIndex === 0 ? facebookProfileSignIn(props) : googleProfileSignIn(props)}  />   
            }
            { props.MainSelectedIndex === 2 && 
                <Button color='#185b61' 
                title="Instruction & Upload"
                onPress={() => { 
                    props.setShowInstruction(true);
                    selectedIndex === 0 ? props.setSocialName('facebook') : props.setSocialName('google'); 
                }} />
            }
            </View> 
            </>
    );
}

const PersonalProfile = (props) => {
    return (
        <View style={[{ flexDirection: 'column', alignItems: 'center', margin:5 }]}>
            <Text style={BaseStyle.personal}>Personal Profile</Text>
            <Image style={[{borderRadius:50, height:100,width:100}]} source={{ uri: props.profilePhotoLink }} />
            <Text style={{ paddingTop: 4, fontSize: 18 }}>
                {props.fullName}
            </Text>
        </View>
    );
}