import React from 'react';
import { Image, Text, TouchableOpacity, Platform, Alert } from 'react-native';
import * as Linking from 'expo-linking';
import * as AuthSession from 'expo-auth-session';
import { googleSignIn, facebookSignIn } from '../services/SocialSignInService';
import { googleProfileSignIn, facebookProfileSignIn } from '../services/SocialSignInService';

export default function SocialSignIn(props) {
    return (
        [<TouchableOpacity style={[props.buttonStyle, { marginBottom: 10, backgroundColor: '#F34A38', flexDirection: 'row', justifyContent: 'center' }]} 
        accessibilityRole="button"
        onPress={() => props.isProfile ? googleProfileSignIn(props) : google(props) } key="1">
        <Image style={props.iconImgStyle} source={require("../assets/images/google-plus.png")} />
        <Text style={props.btnTextStyle}>{props.isProfile ? 'Google Profile' : 'Sign in with Google'}</Text>
      </TouchableOpacity>,
      <TouchableOpacity style={[props.buttonStyle, { marginBottom: 10, backgroundColor: '#3578E5', flexDirection: 'row', justifyContent: 'center' }]} 
	  accessibilityRole="button" 
	  onPress={() => props.isProfile ? facebookProfileSignIn(props) : facebook(props) } key="2">
        <Image style={props.iconImgSecondStyle} source={require("../assets/images/facebook.png")} />
        <Text style={props.btnTextStyle}>{props.isProfile ? 'Facebook Profile' : 'Sign in with Facebook'}</Text>
      </TouchableOpacity>]
    );

}

async function google(props) {
    if(Platform.OS === "web" ) {
        googleSignIn();
        return;
    }
    alert('client id - 434040208087');
    let googleUrl = "https://accounts.google.com/o/oauth2/auth?";
    googleUrl = googleUrl + encodeURIComponent("client_id") +"=";
    googleUrl = googleUrl + encodeURIComponent("434040208087-dj58pcbi5tkmroo655c9sis9h6n6pqaq.apps.googleusercontent.com") + "&";
    googleUrl = googleUrl + encodeURIComponent("access_type") + "=offline&";
    googleUrl = googleUrl + encodeURIComponent("response_type") + "=code&";
    googleUrl = googleUrl + encodeURIComponent("redirect_uri") + "=";
    googleUrl = googleUrl + encodeURIComponent("https://auth.expo.io/@cullsin/cxsphere-individual-app") + "&scope=";
    googleUrl = googleUrl + encodeURIComponent("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read");
    let response = await AuthSession.startAsync({"authUrl": googleUrl});
	if (response.type === 'success') {
      props.navigate("LoginCallback", response.params);
    }
}

async function facebook(props) {
    if(Platform.OS === "web" ) {
        facebookSignIn();
        return;
    }
	
    let fbUrl = "https://www.facebook.com/v5.0/dialog/oauth?state=test&";
    let returl = Linking.makeUrl();
    fbUrl = fbUrl + encodeURIComponent("client_id") +"=";
    fbUrl = fbUrl + encodeURIComponent("759831194979549") + "&";
    fbUrl = fbUrl + encodeURIComponent("response_type") + "=code&";
    fbUrl = fbUrl + encodeURIComponent("redirect_uri") + "=";
    fbUrl = fbUrl + encodeURIComponent("https://auth.expo.io/@cullsin/cxsphere-individual-app") + "&scope=email";
    let response = await AuthSession.startAsync({"authUrl": fbUrl});
	console.log(response);
	if (response.type === 'success') {
      console.log(props);
	  props.navigate("LoginCallback", response.params);
    }
}