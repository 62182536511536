import React, { useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Header } from 'react-native-elements';
import Label from './Label';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { Color } from '../utils/color';

export const PersonalInformationSubHeader = (props) => {
    const [headerExpanded, setHeaderExpanded] = useState(false);
    return (
        <View style={[{ flexDirection: 'column' }]}>
            <Header containerStyle={{ backgroundColor: Color.WhiteLight, marginBottom: 10}} leftContainerStyle={{flex:3}}>
                <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{ fontWeight: 'bold' }} >{props.title}</Label>
                <></>
                <TouchableOpacity
                    style={[{width:10,height:30  }]}
                    accessibilityRole="button"
                    onPress={() => setHeaderExpanded(!headerExpanded)}>
                    <Text style={[BaseStyle.buttonPlusText, { fontSize: 20, fontWeight: 'bold' }]}>{headerExpanded ? '-' : '+'}</Text>
                </TouchableOpacity>
            </Header>
            {headerExpanded && props.children}
        </View>
    );
}