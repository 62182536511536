import {TouchableOpacity, Text, View, Image } from 'react-native';
import React from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { Color } from '../utils/color';
import Label from '../components/Label';
import { Ionicons } from '@expo/vector-icons';
import { Header } from 'react-native-elements';
import CXSphereRightMenu from './CXSphereRightMenu';

export default function CXSphereBanner(props) {
    return (
        <Header containerStyle={{backgroundColor:Color.White, height:100}}>
            <View >
                <Image style={BaseStyle.welcomeImage} source={require("../assets/images/logo.png")} />
            </View>
            <Label Barlow_SemiBold style={[BaseStyle.title, ]}>{props.title}</Label>
            <CXSphereRightMenu {...props} />

            </Header>
    );
}
