import { setNotificationCategoryAsync } from 'expo-notifications';
import React, { useState, useEffect } from 'react';
import { Text, View, Linking, Platform } from 'react-native';
import { Card, Button } from 'react-native-elements';
import { fetchCoupons, generateCouponLink } from '../services/RewardService';

const Coupons = (props) => { 
    const limit = 10;
    const [ coupons, setCoupons] = useState([]);
    const [ after, setAfter] = useState(0);
    const [isDone, setIsDone] = useState(false);
    useEffect(() => {
        fetchCoupons(props.accessToken, limit, after)
        .then(res => {
            let newCoupons = coupons;
            newCoupons = newCoupons.concat(res.rewards);
            setCoupons(newCoupons);
            if(res.rewards.length === 0) {
                setIsDone(true);
            } 
        });
    }, [after]);
    
    const totalRows = Math.floor(coupons.length / 4) + 1;
    const buildCouponCard = (item) => {
    return (    
        <Card containerStyle={{'width': '20%'}}>
            <Card.Title>{item.offer}</Card.Title>
            <Card.Divider />
            <Card.Image source={item.image} resizeMode={'cover'} />
            <View style={{flexDirection:'column', height: 170}}>
            <Text style={{margin: 15}}>{item.product_details}</Text>
            </View>
            <Button 
            onPress={() => props.user_id ?
                Platform.OS === 'web' ? 
                window.open(generateCouponLink(item, props.accessToken)) : 
                Linking.openURL(item.url) : props.navigation.navigate("Login") }
            buttonStyle={{ backgroundColor: '#185b61'}} 
            title={item.coupon_text} />
        </Card>
        );
    }

    return (
    <Card>
        <Card.Title> Coupons </Card.Title>
        <Card.Divider/>
        {
         [...Array(totalRows)].map((unrow,row) =>        
        <View style={{flexDirection:'row', paddingLeft: 50, marginBottom: 20 }}>
        {
            [...Array(4)].map((uncolumn, column) =>
                <React.Fragment> 
                    {coupons[(4 * row) + column] && buildCouponCard(coupons[(4 * row) + column])}
                </React.Fragment>
            )
        }
        </View>
        )}
        { isDone === false && <Button 
            onPress={() => setAfter(after + 10)}
            buttonStyle={{ backgroundColor: '#185b61'}} 
            title={'More Coupons'} /> }
    </Card>
    );
}

export default Coupons;