import React, { useEffect, useState, useContext } from 'react';
import { BaseStyle } from '../../stylesheets/BaseStyle';
import {  Text, View, StyleSheet, TouchableOpacity , Alert, ScrollView, Dimensions, Platform, Linking  } from 'react-native';
import { Modal, TouchableHighlight } from 'react-native';
import { DataTable, Card } from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import { Image, Button, Icon } from 'react-native-elements';
import { Color } from '../../utils/color';
import config from '../../config';
import { UserContext } from '../../UserContext';
import {fetchProduct, addToCart } from '../../services/ProductService';

export default function MobileProductContent(props) {

    const [start, setStart] = useState(0);
    const [ModalProductVisible, setModalProductVisible] = useState(false);
    const [OrderVisible, setOrderVisible] = useState(false)
    const [isbuttonClick, setIsbuttonClick] = useState(false)
    const [inputSearch, setInputSearch] = useState('')
    const [searchHeader, setSearchHeader] = useState('');
    const [sortName, setSortName] = useState(null);
    const [sortColumn, setSortColumn] = useState(0);
    const [sortType, setSortType] = useState(0);
    const [ProductViewSelectedRow, setProductViewSelectedRow] = useState(0)
    const [selectedRow, setSelectedRow] = useState([])
    const [isAdd2CartClick, setisAdd2CartClick] = useState(false)
    const [products, setProducts] = useState({
        header: [], 
        content:[],
        rawProducts:[]
    }); 
    
    const numberofRows = 3;
    const [orders, setOrders] = useState([]);

    const openProductView = (visible,index) => {
      setModalProductVisible(visible);
      setOrderVisible(false)
      setProductViewSelectedRow(index) 
    }

    const setItems = (visible, id, store_id) => {
      setOrderVisible(visible); 
      setModalProductVisible(visible);
      setSelectedRow([...selectedRow, id]);
      const reOrder = reformOrder(id, store_id, products.rawProducts)
      setOrders(reOrder);
    }
    
    const reformOrder = (id, store_id, rawData ) => {
        let data = orders;
        
        if(!getProduct(id, store_id, rawData))
        return data;

        if(!data.length) {
          let store = getProduct(id, store_id,rawData)
          store.items = [];
          store.names = [store.product_name]
          store.items.push({
            'oprice': store.attribute_data.price,
            'currency': store.attribute_data.currency,
            'item': store.product_name,
            'weight': store.attribute_data.size ||  store.attribute_data.weight,
            'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
            "id" : store.cxs_product_id,
            "store_id": store.store_details.store_id,
            'price' : Number(store.attribute_data.price),
            'counter': 1
          })
          data.push({store})
          return data;
        }

        let store = getProduct(id, store_id, rawData) ;
        let isExists = false;
            for(let j=0;j<data.length;j++) {
              if(data[j].store.store_details.store_name === store.store_details.store_name) {
                if(data[j].store.names.indexOf(store.product_name) === -1) {
                  data[j].store.items.push({
                    'oprice': store.attribute_data.price,
                    'currency': store.attribute_data.currency,
                    'item': store.product_name,
                    'weight': store.attribute_data.size ||  store.attribute_data.weight,
                    'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
                    "id" : store.cxs_product_id,
                    "store_id": store.store_details.store_id,
                    'price' : Number(store.attribute_data.price),
                    'counter': 1
                  })
                  data[j].store.names.push(store.product_name)
                }
                isExists = true;
              } 
            }
  
        if(isExists === false) {
          let store = getProduct(id, store_id, rawData)
          store.items = [];
          store.names = [store.product_name]
          store.store_names = [store.store_name]
          store.items.push({
            'oprice': store.attribute_data.price,
            'currency': store.attribute_data.currency,
            'item': store.product_name,
            'weight': store.attribute_data.size ||  store.attribute_data.weight,
            'amount': store.attribute_data.currency + ' ' + store.attribute_data.price,
            'price' : Number(store.attribute_data.price),
            "id" : store.cxs_product_id,
            "store_id": store.store_details.store_id,
            'counter': 1
          })
          data.push({store})
        } 
        return data;
    }

    const incrementItem = (id,store_id) => {
        let data = orders;
        for(let i=0;i<data.length;i++) {
            let storeItems = data[i].store.items;
            for(let j=0;j<storeItems.length;j++) {
                if(storeItems[j].id === id && storeItems[j].store_id === store_id ) {
                  storeItems[j].counter = Number(storeItems[j].counter) + 1;
                  storeItems[j].price = Number(storeItems[j].counter) * Number(storeItems[j].oprice);
                  storeItems[j].amount = storeItems[j].currency + ' ' + storeItems[j].price 
                }
            }
        }
        setItems(true,990, 10000);
    }

    const decrementItem = (id,store_id) => {
      let data = orders;
      for(let i=0;i<data.length;i++) {
          let storeItems = data[i].store.items;
          for(let j=0;j<storeItems.length;j++) {
              if(storeItems[j].id === id && storeItems[j].store_id === store_id) {
                if(storeItems[j].counter > 1) {
                  storeItems[j].counter = Number(storeItems[j].counter) - 1;
                  storeItems[j].price = Number(storeItems[j].counter) * Number(storeItems[j].oprice);
                  storeItems[j].amount = storeItems[j].currency + ' ' + storeItems[j].price
                } 
              }
          }
      }
      setItems(true,990,10000);
    }

    const subTotal = () => {
      let data = orders;
      let price = 0;
      let currency;
      for(let i=0;i<data.length;i++) {
          let storeItems = data[i].store.items;
          for(let j=0;j<storeItems.length;j++) {
                 price += storeItems[j].price;
                 currency = storeItems[j].currency;
          }
      }
      return currency + ' ' + price;
    }

    const getProduct = (id, store_id, rawData) => {
      for(let i=0;i<rawData.length;i++) {
         if(rawData[i].cxs_product_id === id && rawData[i].store_details.store_id === store_id) {
            return rawData[i]
         }
      }
      return null;
    }

    useEffect( () => {
      fetchProduct(numberofRows,start, searchHeader, inputSearch).then(arg => {
        if(products.rawProducts.length) {
          let productData = arg;
          productData.header = arg.header,
          productData.content = products.content.concat(arg.content)
          productData.rawProducts= products.rawProducts.concat(arg.rawData)
          setProducts(productData)
        } else {
          setProducts(arg)
        }
      })
      }, [start, isbuttonClick]);


 useEffect( () => {
    if(orders.length > 0)
      addToCart(orders, props.user_id).then(arg => {
        setOrders([])
        setModalProductVisible(false)
      })   
  }, [isAdd2CartClick]);

  const submitSearch = () => {
     setIsbuttonClick(!isbuttonClick)
  } 

   return (
     <>
      <View style={styles.centeredProductView}>
      { ModalProductVisible && <Modal
        id="modal-product"
        animationType="slide"
        visible={ModalProductVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalProductVisible(false)
        }}>
        { OrderVisible === false && <ScrollView>    
        <View style={styles.modalProductView}> 
            <TouchableHighlight
              style={{ ...styles.openButton}}
              onPress={() => {
                setModalProductVisible(false);
              }}>
              <Text style={styles.textStyle}>X</Text>
            </TouchableHighlight>
            <View>
            {
              products.header.map((item, index) => {
                return (
                <View style={{marginBottom: 10}} key={index}>
                <Text style={{
                fontFamily: 'Barlow-Regular', 
                color: Color.DarkGreen, 
                fontWeight:'600'}}>{item}</Text>

                <Text style={{
                    fontFamily: 'Barlow-Regular', fontSize:13, marginBottom:3
                    }}> {products.content[ProductViewSelectedRow][item.toLowerCase().replace(' ', '_')]} </Text>
                </View>
                )
              })
            }  
            </View>
            <View style={{ width: '100%' }}>
            <Button buttonStyle={{ backgroundColor: Color.DarkGreen, alignSelf:'center'}} 
                            onPress={() => setItems(true,products.content[ProductViewSelectedRow].id, products.content[ProductViewSelectedRow].store_id )}
                            title="Pre order Now">
                            </Button>
                    </View>
          </View>
          </ScrollView>}
          { OrderVisible === true && <ScrollView>
            <View style={styles.modalOrderView}>
            <TouchableHighlight
              style={{ ...styles.openButton}}
              onPress={() => {
                setModalProductVisible(!ModalProductVisible);
              }}>
              <Text style={styles.textStyle}>X</Text>
            </TouchableHighlight>
            <View style={{marginTop:10}}>
            <Text style={{
                fontFamily: 'Barlow-Regular', 
                color: Color.DarkGreen,
                fontSize:18,
                fontWeight:'800'}}>Order details</Text>
                {
                  orders.map((item)=> { 
                  return (
                    <>
                    <View style={{flex:1, flexDirection:'row', marginTop:10, paddingBottom:5, 
                    width:'100%', 
                    borderColor:'#fff',
                    borderBottomColor:'#000', 
                    borderWidth:1 }}>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_name} </Text>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_address} </Text>
                        <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {item.store.store_details.store_contact} </Text>
                    </View>  
                      <View style={{width:'100%'}}>
                      {
                        item.store.items.map(elem => {
                          return (
                            <View style={{flex:1, flexDirection:'row', marginTop:10, width:'100%'}}>
                              <View style={{ width:'25%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.item} </Text>
                              </View>  
                              <View style={{ width:'25%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.weight} </Text>
                              </View>    
                              <View style={{ width:'30%',paddingLeft:10, height:25 }}>
                                <View style={{ flex:1, borderRadius:7, flexDirection:'row', width: 50, backgroundColor: '#eee', alignItems:'center'}}>  
                                  <TouchableOpacity onPress={() => decrementItem(elem.id,elem.store_id) } style={{width:20, paddingLeft:4}}>
                                        <Text>-</Text>
                                  </TouchableOpacity>
                                        <Text style={{ fontFamily: 'Barlow-Regular',fontSize:12, color: Color.DarkGreen, alignSelf:'center' }}>{elem.counter}</Text>
                                  <TouchableOpacity onPress={() => incrementItem(elem.id, elem.store_id) } style={{width:20, alignItems:'flex-end'}}>
                                        <Text>+</Text>
                                  </TouchableOpacity>      
                                </View>
                              </View>    
                              <View style={{ width:'20%',padding:1 }}>
                                <Text style={{fontFamily: 'Barlow-Regular',fontSize:12}}> {elem.amount} </Text>
                              </View>    
                            </View>  
                          )
                        })
                      }
                    </View>  
                    </>
                  )})
                }
                <View style={{flex:1, flexDirection:'row', width:'100%', }}>
                      <View style={{ width:'30%', backgroundColor:'#fff',         
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'50%',          
                                    padding:10,backgroundColor:'#fff', alignItems:'flex-end' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular',fontWeight:'600', 
                            fontSize:10, color:'#000'}}> Subtotal </Text>
                      </View>
                      <View style={{ width:'20%',          
                                    padding:10,backgroundColor:'#fff' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#000'}}> {subTotal()} </Text>
                      </View>
                </View>
                <View style={{flex:1, flexDirection:'row', width:'100%', }}>
                      <View style={{ width:'20%', backgroundColor:'#eee',          
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'60%', backgroundColor:'#eee',         
                                    padding:10,backgroundColor:'#eee', alignItems:'flex-end' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:10, color:'#000'}}> Discount by Cxsphere </Text>
                      </View>
                      <View style={{ width:'20%',
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#000'}}> INR 00 </Text>
                      </View>
                </View>
                <View style={{flex:1, flexDirection:'row', width:'100%', }}>
                      <View style={{ width:'10%',backgroundColor:'#eee',          
                                    padding:10 }}>
                      </View>
                      <View style={{ width:'70%', alignItems:'flex-end',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular',fontWeight:'800', 
                            fontSize:10, color:'#000'}}> Total </Text>
                      </View>
                      <View style={{ width:'20%',          
                                    padding:10,backgroundColor:'#eee' }}>
                          <Text style={{
                            fontFamily: 'Barlow-Regular', 
                            fontSize:12, color:'#000'}}> {subTotal()}  </Text>
                      </View>
                </View>
                <View style={{flex:1, flexDirection:'row', width:'100%'}}>
                  <TouchableOpacity onPress={() => setisAdd2CartClick(!isAdd2CartClick) }>
                      <View style={styles.submitBtn}>
                        <Text style={styles.btnText}>Pre order now</Text>
                      </View> 
                  </TouchableOpacity>
                </View>           
            </View>
          </View>
          </ScrollView>}
      </Modal> } 
      </View> 
    <ScrollView>
    <View style={{ flexDirection:'row', backgroundColor:'#eee', padding: 10 }}>
        <Text style={{ fontSize: 20, fontFamily: 'Barlow-Regular' }}> About {products.count} results </Text>
    </View>
    <View style={{ flexDirection:'row', backgroundColor:'#fff', marginTop: 10, padding:10 }}>
        <View style={{ width:'40%'}}>
          <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Store </Text>
        </View>
        <View style={{ width:'30%'}}>
          <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Price Range </Text>
          </View>
        <View style={{ width:'30%', alignItems:'flex-end'}}>
            <Text style={{ fontSize: 14, fontFamily: 'Barlow-Regular', color:Color.DarkGreen }}> Distance </Text>
        </View>
    </View>
    {
       products.content.map((item, key) => 
        <View key={key} style={{ flexDirection:'row', backgroundColor:'#fff', padding:10, borderBottomWidth:1, borderColor:'#eee' }}>
            <View style={{ width:'40%'}}>
              <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular', fontWeight:'600' }}> {item.store_name} </Text>
              <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.address} </Text>
              <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.contact} </Text>
            </View>
            <View style={{ width:'30%'}}>
              <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.price_range} </Text>
              </View>
            { item.type === 'physical' && ( 
            < View style={{ width:'30%', alignItems:'flex-end'}}>
                <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular' }}> {item.distance} </Text>
                <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular', paddingTop: 30, color:Color.DarkGreen }}
                onPress={() => openProductView(true, key)}
                > Pre Order Now! </Text>
            </View>
            )}
            { item.type === 'digital' && ( 
            < View style={{ width:'30%', alignItems:'flex-end'}}>
                <Text style={{ fontSize: 12, fontFamily: 'Barlow-Regular', paddingTop: 30, color:Color.DarkGreen }}
                onPress={() => Linking.openURL(item.url)}
                > View Now! </Text>
            </View>
            )}
        </View>      
       )
    }
    <View>
    {products.count > (start + numberofRows) && 
                            <Button buttonStyle={{ width: 100, backgroundColor: Color.DarkGreen, alignSelf:'center', marginRight:20 }} 
                            onPress={() => setStart(start + numberofRows)}
                            title="More">
                            </Button>
                            }
    </View>
    </ScrollView>
    </>
   )
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#eee', width:'100%' },
  head: { height: 50, backgroundColor: '#EEE', padding:10 },
  text: { fontSize:12, color: Color.DarkGreen, fontFamily: 'Barlow-Regular', 
  fontWeight:'600' },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
  rowText: {fontFamily: 'Barlow-Regular', fontSize:12 },
  btn: { width: 100, height: 30, backgroundColor: 'rgb(0, 89, 97)',  borderRadius: 10, padding:10 },
  submitBtn: { width: 100, height: 30, 
    backgroundColor: 'rgb(0, 89, 97)',  
    borderRadius: 10, padding:5 },
  btnText: { textAlign: 'center', color: '#fff', fontSize:12 },
  inputBox: {
    backgroundColor: Color.White,
    width: 400,
    marginRight: 20
  },
  centeredProductView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    zIndex:9999,
    position:'absolute',
    top:10,
    right:5
  },
 
  centeredOrderView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    zIndex:199999,
    position:'absolute',
    top:10,
    right:5
  },

  MenuContainerView: {
    flex: 1,
    alignSelf:'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex:9999,
    position:'absolute',
    top:Dimensions.get("window").height - 200,
    right:5
  },
  countText: { fontSize:12, color: Color.DarkGrey, fontFamily: 'Barlow-Regular', 
  fontWeight:'600', paddingLeft:18, paddingRight:18 },
  modalProductView: {
    //width: Dimensions.get("window").width,
    width: 300,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    padding: 30
  },
  
  modalOrderView: {
    width: Dimensions.get("window").width,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    padding: 30
  },
  
  openButton: {
    backgroundColor: '#fff'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  queryContainer:{
    flex:1,
    flexDirection:'row',
    minHeight:60,
    padding:10,
    marginLeft: 40,
    backgroundColor: '#fff',
    margin: 5,
    marginRight: 45,
    borderRadius:5,
    width:'93.4%'
  },
  textDropDownStyle: {
    borderWidth: 1,
    borderColor: '#DEDEDE',
    width: '100%',
    borderRadius: 5,
    fontSize: 14,
    padding: 12,
    marginBottom: 12,
    fontFamily: 'Barlow-Regular'
  }
});