import React,{useContext} from 'react';
import { Text, View } from 'react-native';
import { UserContext } from '../UserContext';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { Color } from '../utils/color';
import ProfileContent from '../components/ProfileContent';
import AuthenticatedLayout from './AuthenticatedLayout';
import {Helmet} from "react-helmet";

export default function DashboardScreen(props) {
    const userData = useContext(UserContext);
    return (
        <>
         <Helmet>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <title>Profile Sharing page</title>
        </Helmet>
        <AuthenticatedLayout {...props} title="Profile">
            {originalWidth => (<ProfileContent {...userData.userData} originalWidth={originalWidth} {...props} />)}
        </AuthenticatedLayout>
        </>
    );
}
DashboardScreen.navigationOptions = {
    header: null, title: 'Dashboard',
}