import config from '../config';
import {postFetchCall,  makeFetchCall} from './BaseService';

export const fetchBrandCollaboration = async (props) => {
    let response = await makeFetchCall(config.apiurl + '/user/brandcollaborations', props.accessToken);
    let responseJson = await response.json();
    return responseJson;
}
export const fetchFavoriteBrands = async (accessToken) => {
    let response = await makeFetchCall(config.apiurl + '/favorite-brands', accessToken);
    let responseJson = await response.json();
    console.log("Brands: " + JSON.stringify(responseJson));
    return responseJson;
}
export const favoriteBrand = async(accessToken, source, sourceId) => {
    let response = await postFetchCall(config.apiurl + '/favorite-brands', accessToken, {'source': source, 'sourceId': sourceId});
    let responseJson = await response.json();
    console.log(JSON.stringify(responseJson));
    return responseJson;
}