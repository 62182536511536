import React, { useState } from 'react';
import {
  View, Text,
  Image, TouchableOpacity,
} from 'react-native';
import { Color } from '../utils/color';
import Label from '../components/Label';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { AntDesign } from '@expo/vector-icons';
export default function  AppSideMenu(props) {

    const [open, setOpen] = useState(true)
    return (
      <View style={[{flexDirection: 'column',width:230, height: props.height, backgroundColor:Color.DarkGreen, paddingTop:10}]} >
        <TouchableOpacity style={[BaseStyle.menuItem]} onPress={()=>props.navigation.navigate("Dashboard")}>
          <Text style={[BaseStyle.menuText]}>Profile</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[BaseStyle.menuItem]} onPress={()=>props.navigation.navigate("Summary")}>
          <Text style={[BaseStyle.menuText]}>Summary</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[BaseStyle.menuItem]} onPress={()=>props.navigation.navigate("Rewards")}>
          <Text style={[BaseStyle.menuText]}>Rewards</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[BaseStyle.menuItem]} onPress={()=>props.navigation.navigate("Notifications")}>
          <Text style={[BaseStyle.menuText]}>Notifications</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[BaseStyle.menuItem]} onPress={() => setOpen(!open)}>
          <Text style={[BaseStyle.menuText]}>Product</Text>
          <AntDesign name="downcircleo" size={16} color="white" style={{ marginLeft: 100}} />
        </TouchableOpacity>
        { open && <>  
        <TouchableOpacity style={[BaseStyle.submenuItem]} onPress={()=>props.navigation.navigate("Product")}>
          <Text style={[BaseStyle.submenuText]}>
          <AntDesign name="search1" size={16} color="white" /> Product Search
            </Text>
        </TouchableOpacity>  
        <TouchableOpacity style={[BaseStyle.submenuItem]} onPress={()=>props.navigation.navigate("History")}>
          <Text style={[BaseStyle.submenuText]}>
            History
          </Text>
        </TouchableOpacity>
        {/*      
        <TouchableOpacity style={[BaseStyle.submenuItem]} onPress={()=>props.navigation.navigate("Product")}>
          <Text style={[BaseStyle.submenuText]}>V Uploader</Text>
        </TouchableOpacity>
        */} 
          </>
        }
      </View>
    );
}

