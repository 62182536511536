import React, { useEffect, useState } from "react";
import { Alert, Modal, StyleSheet, Text, Pressable, View, Button } from "react-native";
import { Card } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage';

const OrganizationInteraction = (props) => {

  const [Organization, setOrganization] = useState({ org_id: null, org_name: null});
  const [thanks, setThanks] = useState(false);

  useEffect(() => {
    const getOrg = async () => { 
      setOrganization(JSON.parse(await AsyncStorage.getItem('Organization')));
   } 
   getOrg();
  },[])
  
  const sendOrganizationInfo = async() => {
        const User = JSON.parse(await AsyncStorage.getItem('user'));
        const url = 'https://rewards.cxsphere.com/mautic/email';
        let response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.accessToken
          },
          body: JSON.stringify({
            organization_id : Organization.org_id,
            email_address: User.email,
            organization_name : Organization.org_name,
            email_type: 'thank_you_for_data'
          })
      });
      
      const contextUrl = `https://contextapi.cxsphere.com/context/user/enqueue/${User.user_id}/${Organization.org_id}`;
      response = await fetch(contextUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.accessToken
        },
        body: JSON.stringify({})
      });

      setThanks(true);
      return response;
  }

  if(thanks === false) {
    return (
      <View style={{margin:30}}> 
        <Card>
          <Card.Title>Consent from you</Card.Title>
          <Text style={{fontSize: 18}}>
    We understand that you have been referred to this page by {Organization.org_name} and are happy to 
    share your information to {Organization.org_name}. </Text><Text style={{fontSize: 18}}>
  Please be assured that your information is safe with us and will not be share with anyone else without your explicit consent.      </Text>
          <View style={{'flexDirection':'row', alignSelf:'center'}}>
            <View style={{ margin: 13}}> 
              <Button color='#185b61' title="Accept" onPress={() => sendOrganizationInfo()} />
            </View>
            <View style={{ margin: 13}}>
              <Button color='#900900' title="Cancel" onPress={() => props.navigation.navigate("Dashboard")} />
            </View>
          </View>
        </Card>
      </View>  
    )
  }

  if(thanks === true) {
    return (
      <View style={{margin:30}}> 
        <Card>
          <Card.Title>Thank you</Card.Title>
          <Text style={{fontSize: 18}}>
          Thank you for submitting your information to {Organization.org_name}. To view your personal information 
          or use it to connect to other organizations, please continue...
          </Text>
          <View style={{'flexDirection':'row', alignSelf:'center'}}>
            <View style={{ margin: 13}}>
              <Button color='#185b61' title="Proceed" onPress={() => props.navigation.navigate("Dashboard")} />
            </View>
          </View>
        </Card>
      </View>  
    )
  }
};

export default OrganizationInteraction;