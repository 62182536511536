import React from 'react';
import { Text } from 'react-native';
import { Card } from 'react-native-elements';

export const formatStringArrays = (ar) => {
    return ar.join();
}
const InfoRequestHeader = (props) => <Text style={{ fontWeight: 'bold', fontSize: 45 }}>{props.request.companyName}</Text>;
const InfoRequestText = (props) => <Text style={{ fontSize: 18, marginBottom: 5 }}>{props.infoText}</Text>;
const Description = (props) => <InfoRequestText infoText={props.request.description} />;
const AgeRange = (props) => {
    return ("ageRange" in props.request.informationRequest ?
    <InfoRequestText infoText={"Age range: " + formatStringArrays(props.request.informationRequest.ageRange)} /> :
    null);
}
const Gender = (props) => {
    return ("gender" in props.request.informationRequest ?
    <InfoRequestText infoText={"Gender: " + formatStringArrays(props.request.informationRequest.gender)} /> :
    null);
}
const parseCondition =(condition) => {
    let value = "";
    value = {
        "contains": "like",
        "not_contains": "not like",
        "equal": "equals",
        "not_equals": "not equals"
    }[condition.operator];
    if (Array.isArray(condition.value)) {
        value = value + " " + formatStringArrays(condition.value);
    } else {
        value = value + " " + condition.value;
    }
    return value;
}
const Languages = (props) => {
    return ("languages" in props.request.informationRequest ?
    <InfoRequestText infoText={"Languages: " + parseCondition(props.request.informationRequest.languages)} /> :
    null);
}
const Locations = (props) => {
    return("locations" in props.request.informationRequest ?
    <InfoRequestText infoText={props.request.informationRequest.locations.locationType + " " + parseCondition(props.request.informationRequest.locations.condition)} /> :
    null);
}
const Filters = (props) => {
    return("filters" in props.request.informationRequest ?
    <Card>
        <Text style={{fontWeight:'bold',fontSize:25}}>Filters:</Text>
        {props.request.informationRequest.filters.source.map((source, index) => {
            return (
                <InfoRequestText infoText={source.name + " " + parseCondition(source.condition)} />
            );
        })}
    </Card> :
    null
    );
}
export const InformationRequestDetails = (props) => {
    return (
        <>
            <InfoRequestHeader {...props} />
            <Description {...props} />
            <AgeRange {...props} />
            <Gender {...props} />
            <Languages {...props} />
            <Locations {...props} />
            <Filters {...props} />
            {/* <Text style={{fontSize:18}}>{JSON.stringify(props.request.informationRequest)}</Text> */}
        </>
    )
}