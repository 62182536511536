import React, { useEffect, useState, useContext } from 'react';
import {  Text, View, StyleSheet, TouchableOpacity , ScrollView, Dimensions  } from 'react-native';
import AuthenticatedLayout from './AuthenticatedLayout';
import { Color } from '../utils/color';
import config from '../config';
import { UserContext } from '../UserContext';
import { fetchHistory } from '../services/ProductService';

export default function HistoryScreen(props) {
    const userData = useContext(UserContext);
    return (
        <AuthenticatedLayout {...props} title="Order History">
            {originalWidth => (<HistoryContent {...userData.userData} {...props} />)}
        </AuthenticatedLayout>
    );
}

function HistoryContent(props) {
    const [historyData, setHistoryData] = useState({ orders:[]
    }); 
    
    useEffect( () => {
        fetchHistory(props.user_id).then(arg => setHistoryData(arg))
        }, []);

    return (
        <>
        <View style={styles.container}>
        <View style={{backgroundColor:Color.DarkGreen, 
            flex:1, flexDirection:'row', 
            padding:15 
            }}>
        <View style={{ width:'15%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Order ID# </Text>
        </View>
        <View style={{ width:'25%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Store Address </Text>
        </View>
        <View style={{ width:'20%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Item </Text>
        </View>
        <View style={{ width:'10%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Qty </Text>
        </View>
        <View style={{ width:'10%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Size/Weight </Text>
        </View>        
        <View style={{ width:'10%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Price </Text>
        </View>        
        <View style={{ width:'10%'}}>
              <Text style={{ fontFamily: 'Barlow-Regular',
                        fontSize: 12, 
                        color:'#fff' }}> Discount Amount </Text>
        </View>        

        </View>
        {
            historyData.orders.map((top) => 
                <View style={{ 
                    flex:1, flexDirection:'row', backgroundColor:'#fff' 
                    }}>
                <View style={{ width:'15%', padding:10}}>
                      <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {top.created_date} </Text>
                      <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000', fontWeight:'600' }}> {top.order_id} </Text>
                      <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000', fontWeight:'600' }}> Total: {top.final_amount} </Text>
                </View>
                <View style={{ 
                    flex:1, flexDirection: 'column', backgroundColor:'#fff' 
                    }}>
                {
                    top.stores.map(store =>
                        <View style={{ 
                            flex:1, flexDirection: 'row', backgroundColor:'#fff', padding:10
                            }}> 
                        <View style={{ width:'30%'}}>
                            <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000', fontWeight:'600' }}> {store.store_name} </Text>
                            <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {store.store_address} </Text>
                            <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {store.store_contact} </Text>
                        </View>
                        <View style={{ width:'85%'}}>
                        {
                            store.products.map(product => 
                            <View style={{flex:1, 
                                flexDirection:'row', padding:5, 
                                borderBottomColor:'#eee', 
                                borderBottomWidth:1}}>
                             <View style={{ width:'27%'}}>
                                <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {product.product_name} </Text>
                            </View>
                            <View style={{ width:'15%'}}>
                                <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {product.quantity} </Text>
                            </View>
                            <View style={{ width:'12%'}}>
                                <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {product.size} </Text>
                            </View>
                            <View style={{ width:'15%'}}>
                                <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {product.price} </Text>
                            </View>
                            <View style={{ width:'15%'}}>
                                <Text style={{ fontFamily: 'Barlow-Regular',fontSize: 12, color:'#000' }}> {product.discount} </Text>
                            </View>
                            </View>
                            )
                        }        
                        </View>
                       </View>                     
                    )
                }
                </View>
                </View>
            )
        }
        </View>
      </>
    );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#eee', width:'100%', padding: 25 }
});