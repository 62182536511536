import React, {useContext, useState, useEffect } from 'react';
import { View, StatusBar, ActivityIndicator, StyleSheet, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import {UserContext} from '../UserContext';

export default function AuthLoadingScreen(props) {
    const userToken = useContext(UserContext);
    console.log("Inside authLoading screen..");
    useEffect(() => {
        console.log("Inside useEffect, fetching user token.");
        if (Object.keys(userToken.userData).length > 0) {
            props.navigation.navigate('App');
        } else {
            props.navigation.navigate('Login');
        }
    }, [userToken]);
    return (
        <View style={styles.container}>
            <ActivityIndicator size="large" animating={true} />
            <StatusBar barStyle="default" />
        </View>
    );
}

AuthLoadingScreen.navigationOptions = {
    header: null,
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});