import React from 'react';
import {
  View,
  SafeAreaView,
  Text,
  Image,
  TouchableOpacity,
  Platform
} from 'react-native';
import { Color } from '../utils/color';
import useNativeLayout from '../hooks/NativeLayoutHook';
import Coupons from '../components/Coupons';

export default function RewardHomeScreen(props) {
    const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();   
    return (
      <SafeAreaView onLayout={_handleLayout}>
      { Platform.OS === "web" && <>  
        <View style={{ flex:1, flexDirection:'row' }}>
          <View style={{
            backgroundColor: Color.White,
            width:'90%',
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "flex-start"
          }}>
            <Image style={{
                width: 100,
                height: 80,
                resizeMode: 'contain'
            }} source={require("../assets/images/logo.png")} />
          </View>
          <View style={{
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "flex-end"
          }}>
          <TouchableOpacity  onPress={()=>props.navigation.navigate("Login")}>
          <Text> Login / Register </Text>
          </TouchableOpacity>
          </View>  
          </View>
          <View style={{backgroundColor:'#eee', padding:30, overflow:'scroll'}}> 
                <Coupons {...props} />
          </View>
        </>                    
      }
      </SafeAreaView>
    );
}

RewardHomeScreen.navigationOptions = {
  header: null,
};
