import {Platform, StyleSheet, Dimensions} from 'react-native';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { Color } from '../utils/color';

const base = StyleSheet.create({
    Container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    mobileHeader: {
      paddingTop: (Platform.OS == 'ios' ? 20 : 40),
      paddingBottom: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: "center"
    },
    logoDesktop: {
      width: '100%',
      backgroundColor: Color.White,
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: '10%',
    },
    logoImgMobile: {
      display: 'none',
    },
  
    button: {
      alignItems: 'center',
      justifyContent: "center",
      backgroundColor: '#005961',
      borderRadius: 5,
      marginTop: 5,
      marginBottom: 10,
      height: 45,
    },
    BackBtn: {
      marginTop: 25,
      flexDirection: 'row',
      alignItems: "center",
    },
    welcomeImage: {
      width: 100,
      height: 80,
      resizeMode: 'contain',
    },
    login: {
      width: 400,
      paddingLeft: 0,
      marginLeft: 'auto',
    },
    title: {
      fontSize: 36,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 50,
      marginTop: 50,
    },
    boxRow: {
      alignItems: 'center',
    },
  
  });
  
  const styles = createStyles(
    base,
    maxWidth(1024, {
      logoDesktop: {
        width: '100%',
        backgroundColor: Color.White,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: '10%',
      },
      title: {
        fontSize: 30,
        color: Color.DarkGreen,
        textAlign: 'center',
        marginBottom: 32,
      },
  
    }),
  
    maxWidth(991, {
  
      logoImgMobile: {
        alignItems: 'center',
        paddingBottom: 25,
        paddingTop: 50,
      },
      title: {
        // display: 'none',
      },
      login: {
        width: Dimensions.get("window").width - 30,
        paddingLeft: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    })
  );
export default styles;