import React, {useState} from 'react';
import {Dimensions} from 'react-native';


export default function useNativeLayout() {
    const [dimensions, setDimensions] = useState({originalWidth: Dimensions.get("window").width, originalHeight: Dimensions.get("window").height});
    const _handleLayout = ({ nativeEvent }) => {
        const { width, height } = nativeEvent.layout;
        setDimensions({originalWidth: width, originalHeight: height});
      };
      return [dimensions.originalWidth, dimensions.originalHeight, _handleLayout];
}