import React from 'react';
import { PixelRatio, Platform, Dimensions, StyleSheet } from 'react-native';
import { Color } from "./color";
import { relativeWidth } from "./dimensions";
import * as DeviceInfo from 'expo-device';

const { width, height } = Dimensions.get('window');
const realWidth = height > width ? width : height;

const APPBAR_HEIGHT = Platform.OS === 'ios' ? 44 : 56;
const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 20 : 0;
export const NAV_HEIGHT = APPBAR_HEIGHT + STATUSBAR_HEIGHT;
const TAB_HEIGHT = 49;
const fontBaseX10Small = 12;
const fontBaseX12Small = 14;
const fontBaseXSmall = 9;
const fontBaseSmall = 10;
const fontBaseNormal = 16;
const fontBaseLarge = 17;
const fontBaseXLarge = 20;

const isTablet = async () => {

    let result = await DeviceInfo.getDeviceTypeAsync();
    if (result == DeviceInfo.DeviceType.TABLET) {
        return true
    } else {
        return false
    }
};

const responsiveHeight = (height) => {
    if (!isTablet())
        return height;
    else
        return (height + (height * 0.25));

};

const drawerWidth = () => {
    if (!isTablet())
        return relativeWidth(75);
    else
        return relativeWidth(60);
};

export const cardStyle = {
    borderRadius: 1,
    borderBottomWidth: 0,
    shadowColor: Color.BLACK,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 5,
    shadowRadius: 2,
    elevation: 2,
};

export const circleStyle = {
    height: 70,
    width: 70,
    borderRadius: 35,
    backgroundColor: Color.BORDER_COLOR
};

const responsiveFontSize = (fontSize) => {
    let divider = isTablet() ? 600 : 375;
    return Math.round(fontSize * realWidth / divider);
};

const fontXSmall = responsiveFontSize(fontBaseXSmall);
const fontSmall = responsiveFontSize(fontBaseSmall);
const fontNormal = responsiveFontSize(fontBaseNormal);
const fontLarge = responsiveFontSize(fontBaseLarge);
const fontXLarge = responsiveFontSize(fontBaseXLarge);
const fontX10Small = responsiveFontSize(fontBaseX10Small);
const fontX12Small = responsiveFontSize(fontBaseX12Small);


const largeCutoff = 800
const mediumCutoff = 640


export {
    fontXSmall, fontX12Small, fontX10Small, fontSmall, fontNormal, fontLarge, fontXLarge, drawerWidth, responsiveHeight, width, height, mediumCutoff, largeCutoff,
};
