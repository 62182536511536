import {Platform, StyleSheet, Dimensions} from 'react-native';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { Color } from '../utils/color';

const base = StyleSheet.create({
    Container: {
      flex: 1,
      alignItems: 'center',
    },
    inputBox: {
      backgroundColor: Color.White,
    },
    mobileHeader: {
      paddingTop: (Platform.OS == 'ios' ? 20 : 40),
      paddingBottom: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: "center"
    },
    forgotPasswordText: {
      color: '#959596',
      alignSelf: 'flex-end',
      fontFamily: 'Barlow-Medium',
      color: Color.DarkGrey,
      marginBottom: 20,
    },
    button: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#005961',
      borderRadius: 5,
      height: 45
    },
    btnText: {
      color: '#ffffff',
      fontSize: 14,
    },
    orLineMain: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginOrLineMain: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textOr: {
      color: '#595A5A',
      width: '20%',
      textAlign: 'center'
    },
    borderLine: {
      width: '40%',
      height: 1,
      backgroundColor: '#DEDEDE'
    },
    iconImg: {
      marginRight: 10,
      resizeMode: 'contain',
      width: 19,
      height: 12,
    },
    iconImgSecond: {
      marginRight: 10,
      resizeMode: 'contain',
      width: 30,
      height: 30,
    },
    textAccountMain: {
      //display: "none",
    },
    welcomeImage: {
      width: 100,
      height: 80,
      resizeMode: 'contain',
    },
    login: {
      width: 400,
      paddingLeft: 0,
    },
    registration: {
      width: 400,
    },
    middleLine: {
      width: 2,
      height: '100%',
      backgroundColor: Color.DarkGreen,
      position: 'absolute',
      right: '50%',
      top: 0,
      display: "flex"
    },
    title: {
      fontSize: 36,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 50,
    },
    boxRow: {
      alignItems: 'center',
    },
    scrollMain: {
      alignItems: "center",
      justifyContent: 'center',
      flex: 1,
    }
  
  });
  
  
const styles = createStyles(
    base,
    maxWidth(1400, {
      title: {
        fontSize: 30,
        color: Color.DarkGreen,
        textAlign: 'center',
        marginBottom: 25,
      },
  
    }),
    maxWidth(1024, {
      title: {
        fontSize: 30,
        color: Color.DarkGreen,
        textAlign: 'center',
        marginBottom: 32,
      },
  
    }),
    maxWidth(991, {
  
      scrollMain: {
        alignItems: "center",
        justifyContent: 'center',
        flex: 0,
      },
  
      login: {
        width: Dimensions.get("window").width - 30,
        paddingLeft: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      registration: {
        width: Dimensions.get("window").width - 30,
        paddingRight: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      middleLine: {
        backgroundColor: Color.TRANSPARENT,
      },
      loginOrLineMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      orLineMain: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      textAccountMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15,
        marginBottom: 15,
        //display: 'flex'
      },
      textAccount: {
        fontSize: 14,
        color: 'red',
      },
      textOr: {
        color: '#595A5A',
        fontSize: 14,
        width: '10%',
        textAlign: 'center'
      },
      borderLine: {
        width: '45%',
        height: 1,
        backgroundColor: '#DEDEDE'
      },
    }),
    maxWidth(480, {
      scrollMain: {
        alignItems: "center",
        justifyContent: "flex-start",
        height: Dimensions.get("window").height - 140,
      },
  
    })
  );
export default styles;