import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator
} from 'react-native';
import config from '../config';
import useNativeLayout from '../hooks/NativeLayoutHook';
import Label from '../components/Label';
import TextBox from '../components/textbox';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { Color } from '../utils/color';
import { StdAlert } from './../components/Alert.web'
import { isSamePassword } from '../utils/validation';

export default function ResetPassword(props) {

      const [newPassword, setNewPassword] = useState("")
      const [confirmPassword, setConfirmPassword] = useState("");
      const [isLoading, setLoading] = useState(false);
      const [originalWidth, originalHeight, _handleLayout] = useNativeLayout();
      const token = props.navigation.state.params.token || "";

  const handleText = (name, setValue) => {
    return (text) => {
      setValue(text.replace(/\s/g, ''));
    }
  }

  const validation = () => {
    var message = "";

    if (newPassword.length == 0) {
      message = "Please enter a Password"
    } else if (newPassword.length < 8) {
      message = "Password length must be between 8 and 128"
    } else if (confirmPassword < 8) {
      message = "Confirm Password length must be between 8 and 128"
    } else if (!isSamePassword(confirmPassword, newPassword)) {
      message = "Password & confirm Password doesn't match"
    }

    return message;

  }

  const resetPasswordAPI = async () => {

    let messageAlert = validation();

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        StdAlert(alertTitle, alertText, () => {
          console.log('OK Pressed');
        })
      } else {

        Alert.alert(
          alertTitle,
          alertText,
          [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ],
          { cancelable: false },
        );
      }
      return;
    }
    setLoading(true);

    try {

      let response = await fetch(config.apiurl + '/reset-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "password": newPassword,
          "confirmPassword": confirmPassword,
          "resetToken": token

        }),
      });

      setLoading(false);

      if (response.ok) {
        let responseJson = await response.json();

        console.log("====== responseJson =====", responseJson);

        if (responseJson && responseJson.code == "Success") {

          if (Platform.OS === 'web') {
            StdAlert('CXSphere', responseJson.message, () => {
              props.navigation.navigate("Login")
            })
          } else {
            Alert.alert(
              'CXSphere',
              responseJson.message,
              [
                {
                  text: 'OK', onPress: () => {
                    props.navigation.navigate("Login")
                  }
                },
              ],
              { cancelable: false },
            );
          }

        } else {
          if (Platform.OS === 'web') {
            alert(responseJson.message)
          } else {
            Alert.alert("CXSphere", responseJson.message)
          }
        }

      } else {
        alert("User is not found");
      }

    } catch (error) {
        setLoading(false);
    }

  }

    return (
      <SafeAreaView onLayout={_handleLayout} style={{ flex: 1 }}>
        <View style={[styles.Container, { backgroundColor: originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: "center"
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <Label Barlow_SemiBold style={[styles.title, { display: (originalWidth < 991 ? 'none' : 'flex'), alignSelf: 'center', }]}>Reset Password</Label>
          <View style={styles.boxRow}>
            <View style={[styles.login, { paddingTop: (originalWidth < 991 ? 20 : 0) }]}>


              <TextBox
                textStyle={{ backgroundColor: Color.White }}
                secureTextEntry={true}
                onChangeText={handleText("newPassword", setNewPassword)}
                placeholder="New Password" />

              <TextBox
                textStyle={{ backgroundColor: Color.White }}
                secureTextEntry={true}
                onChangeText={handleText("confirmPassword", setConfirmPassword)}
                onSubmitEditing={() => {
                  //forgotPasswordAPI()
                }}
                placeholder="Confirm Password" />

              <TouchableOpacity onPress={resetPasswordAPI} style={styles.button} >
                {!isLoading && <Label style={{ fontSize: 14 }} Barlow_Regular color={Color.White}>Send</Label>}
                {isLoading && <ActivityIndicator size="small" color="white" />}
              </TouchableOpacity>
            </View>
          </View>
        </View>

      </SafeAreaView >
    );
}

ResetPassword.navigationOptions = {
  header: null,
};
const base = StyleSheet.create({
  Container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mobileHeader: {
    paddingTop: (Platform.OS == 'ios' ? 20 : 40),
    paddingBottom: 20,
    width: '100%',
    justifyContent: 'center',
    alignItems: "center"
  },
  logoDesktop: {
    width: '100%',
    backgroundColor: Color.White,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '10%',
  },
  logoImgMobile: {
    display: 'none',
  },

  button: {
    alignItems: 'center',
    justifyContent: "center",
    backgroundColor: '#005961',
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    height: 45,
  },
  BackBtn: {
    marginTop: 25,
    flexDirection: 'row',
    alignItems: "center",
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
  },
  login: {
    width: 400,
    paddingLeft: 0,
    marginLeft: 'auto',
  },
  title: {
    fontSize: 36,
    color: Color.DarkGreen,
    textAlign: 'center',
    marginBottom: 50,
    marginTop: 50,
  },
  boxRow: {
    alignItems: 'center',
  },

});

const styles = createStyles(
  base,
  maxWidth(1024, {
    logoDesktop: {
      width: '100%',
      backgroundColor: Color.White,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: '10%',
    },
    title: {
      fontSize: 30,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 32,
    },

  }),

  maxWidth(991, {

    logoImgMobile: {
      alignItems: 'center',
      paddingBottom: 25,
      paddingTop: 50,
    },
    title: {
      // display: 'none',
    },
    login: {
      width: Dimensions.get("window").width - 30,
      paddingLeft: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);