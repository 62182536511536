import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import moment from 'moment';
import { PersonalInformationHeader } from './PersonalInformationHeader';
import { PersonalInformationSubHeader } from './PersonalInformationSubHeader';
import { SubHeader } from './SubHeader';
import { EvilIcons } from '@expo/vector-icons';

const fetchSocialShare = async (props) => {
      let response = await fetch('https://mycxapi.cxsphere.com/user/data?at=' + props.accessToken, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + props.accessToken
        },
    });
    let responseJson = await response.json();
    const sourceArray = [];
    for(let i=0;i<responseJson.length;i++) {
            if(responseJson[i].source === 'facebook' || responseJson[i].source === 'google') {
                const message = [];
                const conversation = [];
                const event = [];
                const search = [];
                for(let j=0;j<responseJson[i].behaviour.length;j++) {
                    if(responseJson[i].behaviour[j].interaction_type === 'message') {
                        message.push(responseJson[i].behaviour[j]);     
                    }
                    if(responseJson[i].behaviour[j].interaction_type === 'conversation') {
                        conversation.push(responseJson[i].behaviour[j]);     
                    }
                    if(responseJson[i].behaviour[j].interaction_type === 'event_interaction') {
                        event.push(responseJson[i].behaviour[j]);     
                    }
                    if(responseJson[i].behaviour[j].interaction_type === 'search') {
                        search.push(responseJson[i].behaviour[j]);     
                    }
                }
                responseJson[i].behaviour['message'] = message;
                responseJson[i].behaviour['conversation'] = conversation;
                responseJson[i].behaviour['event'] = event;
                responseJson[i].behaviour['search'] = search;
                sourceArray.push(responseJson[i]);
            }
    }

    console.log(sourceArray);
    if(!sourceArray.message) {
        return sourceArray;
    } else { 
        return [];
    }
}


export const ProfileSocialShare = (props) => {
    const [share, setShare] = useState([]);
    const [start, setStart] = useState([]);
    const [cstart, setcStart] = useState([]);
    const [refresh, setrefresh] = useState(true);

    useEffect( () => {
        if(refresh === true) {
            fetchSocialShare(props)
                .then(arg => {
                    setShare(arg);
                    setrefresh(false);
                    const arr =[];
                    const tot = [];
                    arg && arg.map((item, i) => arr.push({[i] : 10}))
                    arg && arg.map((item, i) => tot.push({[i] : item.behaviour && item.behaviour.length }))
                    setStart(arr);
                    setcStart(arr);
                })
        }    
        }, [refresh]);

        const setDeleteItem = async (item, primary_id, source) => {
            let response = await fetch('https://mycxapi.cxsphere.com/user/data?at=' + props.accessToken, {
              method: 'DELETE',
              body: JSON.stringify({
                data_point_name: item, 
                account_id: primary_id,
                source
              }),
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + props.accessToken
              },
          });
          let responseJson = await response.json();
          let sourceArray = responseJson; 
          console.log("Finished delete profiles.");
          console.log(sourceArray);
          setrefresh(true);
      }
        
    const isCheckGroup = (jsonArray) => {
        let result = false;
        for(let i=0;i<jsonArray.length;i++) {
            if(jsonArray[i].group_name) {
                result = true;
            }
        }
        return result;
    }  

    const loadData = (index, totallength) => {
            const array = [...start]; 
            const object = array[index];
            if(object[index] < totallength)
                object[index] = object[index] + 10;
            array[index] = object;
            setStart(array);   
    }

    const cloadData = (cindex, totallength) => {
        const array = [...cstart]; 
        const object = array[cindex];
        if(object[cindex] < totallength)
            object[cindex] = object[cindex] + 10;
        array[cindex] = object;
        setcStart(array);   
    }

    const loadIndex = (index) => {
         if(!start[index])
         return 10;
         const object = start[index];
         return object[index];
    }

    const cloadIndex = (cIndex) => {
        if(!cstart[cIndex])
        return 10;
        const object = cstart[cIndex];
        return object[cIndex];
   }

    const checkLoadMore = (index, totallength) => {
            const object = start[index];
            if(!object) 
            return true;
            if(object[index] < totallength) 
                return true;
                return false;
    }

    const ccheckLoadMore = (index, totallength) => {
        const object = cstart[index];
        if(!object) 
        return true;
        if(object[index] < totallength) 
            return true;
            return false;
    }

    return (
        <React.Fragment>
            {share && share.map((identity, loopIndex) => {
            return (
                <>
                <PersonalInformationHeader title={identity.account_name} source={identity.source} key={loopIndex}>
                <PersonalInformationSubHeader title={'Identity'}>
                <View style={{flexDirection:'column', flex:1}}>
                    {
                    Object.keys(identity.pii).map((element, i) => {
                        return (
                            <View style={{flexDirection:'row'}} key={i}>
                            <View style={{margin:10,width:'15%'}}><Text>{element}</Text></View>
                            <View style={{margin:10}}><Text>{identity.pii[element]['value']}</Text></View>
                            <View>
                            <TouchableOpacity
                                style={[{width:10,height:30  }]}
                                accessibilityRole="button"
                                onPress={() => setDeleteItem(element,identity.pii[element]['account_id_in_primary_source'], identity.pii[element]['primary_source'])}>
                                <Text style={[{ fontSize: 20, fontWeight: 'bold' }]}><EvilIcons name="close"/></Text>
                            </TouchableOpacity>
                            </View>
                            </View>
                        )                        
                    })
                    }
                </View>
                </PersonalInformationSubHeader>
                <PersonalInformationSubHeader title={'Demographic'}>
                <View style={{flexDirection:'column'}}>
                    {
                    Object.keys(identity.demographic).map((element, i) => {
                        return (
                            <View style={{flexDirection:'row', width:'100%'}} key={i}>
                            <View style={{margin:10, width:'15%' }}><Text style={{ width:400 }}>{element}</Text></View>
                            <View style={{margin:10}}><Text>{identity.demographic[element]['value']}</Text></View>
                            <View>
                            <TouchableOpacity
                                style={[{width:10,height:30  }]}
                                accessibilityRole="button"
                                onPress={() => setDeleteItem(element, identity.demographic[element]['account_id_in_primary_source'], identity.demographic[element]['primary_source'])}>
                                <Text style={[{ fontSize: 20, fontWeight: 'bold' }]}><EvilIcons name="close"/></Text>
                            </TouchableOpacity>
                            </View>
                            </View>
                        )                        
                    })
                    }
                </View>
                </PersonalInformationSubHeader>
                <PersonalInformationSubHeader title={'Behaviour'}>
                <View><Text style={{fontWeight:'600', fontSize:18}}>Group</Text></View>
                <View>
                    {
                        !isCheckGroup(identity.behaviour) && 
                        <View style={{ margin:10, flexDirection:'column'}}>
                               <Text style={{fontWeight:'600'}}>No Groups Found</Text>
                        </View>
                    }
                    {
                    identity.behaviour.length && identity.behaviour.map((element, i) => {
                        return element.group_name ? 
                            <View style={{ margin:10, flexDirection:'column'}} key={i}>
                                <SubHeader title={element.group_name}>
                                <Text style={{padding:10}}>{element.description}</Text>
                                </SubHeader>
                            </View>
                        : <React.Fragment key={i}/>                       
                    })
                    }
                </View>
                <View><Text style={{fontWeight:'600',fontSize:18}}>Post</Text></View>
                <View>
                    {
                    identity.behaviour.message.length && identity.behaviour.message.slice(0, loadIndex(loopIndex)).map((element, i) => {
                        return (
                            <React.Fragment key={i}>
                            <View style={{ margin:10, flexDirection:'column'}}>
                                <Text>{element.date_created}</Text>
                                <Text>{element.message}</Text>
                            {element.children && element.children.map((item, i) => 
                                <Text style={{flexDirection:'row'}} key={i}>
                                    {item.interaction_type && item.reaction_type === 'LIKE' && <EvilIcons color={'red'} size={25} name="like"/> }
                                    {item.interaction_type && item.reaction_type === 'CARE' && <EvilIcons color={'red'} size={25} name="heart"/> }
                                </Text>
                            )}
                            </View>
                            </React.Fragment>);                       
                    })
                    }
                    {
                    checkLoadMore(loopIndex, identity.behaviour.message && identity.behaviour.message.length) && 
                    <View style={{ alignItems:'center', margin:10}}><Text style={{fontWeight:'600',fontSize:18}} onPress={() => loadData(loopIndex, identity.behaviour.length)}> 
                        Load More... 
                    </Text></View> }
                </View>
                <View><Text style={{fontWeight:'600',fontSize:18}}>Conversation</Text></View>
                <View>
                    {
                    identity.behaviour.conversation.length && identity.behaviour.conversation.slice(0, cloadIndex(loopIndex)).map((element, i) => {
                        return (  
                            <React.Fragment key={i}>
                            <View style={{ margin:5, padding: 5, flexDirection:'column', backgroundColor: '#eee'}}>    
                            {element.messages && element.messages.map((item, i) => 
                                <View style={{ margin:2, flexDirection:'column'}}>
                                    <Text>Date : {moment(item.timestamp.timestamp).format()}</Text>
                                    <Text>Sender : {item.sender}</Text>
                                    <Text>Text : {item.content.text}</Text>
                                </View>     
                            )}
                            {element.participants && element.participants.map((item, i) => 
                                <View style={{margin:2,flexDirection:'column'}}>
                                    <Text>Participant {i}: {item.name}</Text>
                                </View>     
                            )}
                            </View>
                            </React.Fragment>)                       
                    })
                    }
                    {
                    ccheckLoadMore(loopIndex, identity.behaviour.conversation && identity.behaviour.conversation.length) && 
                    <View style={{ alignItems:'center', margin:10}}><Text style={{fontWeight:'600',fontSize:18}} onPress={() => cloadData(loopIndex, identity.behaviour.length)}> 
                        Load More... 
                    </Text></View> }
                </View>
                <View><Text style={{fontWeight:'600', fontSize:18}}>Events</Text></View>
                <View>
                    {
                        identity.behaviour.event.length === 0 && 
                        <View style={{ margin:10, flexDirection:'column'}}>
                               <Text style={{fontWeight:'600'}}>No Events Found</Text>
                        </View>
                    }
                    {
                    identity.behaviour.event.length > 0 && identity.behaviour.event.map((element, i) => {
                        return  (
                            <View style={{ margin:10, flexDirection:'column'}} key={i}>
                                   <Text>Name: {element.event.name}</Text>
                                   <Text>Description: {element.event.description}</Text>
                                   <Text>Start: {moment(element.event.start_time.timestamp).format()}</Text>
                                   <Text>End: {moment(element.event.end_time.timestamp).format()}</Text> 
                            </View>
                        );                       
                    })
                    }
                </View>
                <View><Text style={{fontWeight:'600', fontSize:18}}>Search</Text></View>
                <View>
                    {
                        identity.behaviour.search.length === 0 && 
                        <View style={{ margin:10, flexDirection:'column'}}>
                               <Text style={{fontWeight:'600'}}>No Search Found</Text>
                        </View>
                    }
                    {
                    identity.behaviour.search.length > 0 && identity.behaviour.search.map((element, i) => {
                        return  (
                            <View style={{ margin:5, flexDirection:'row'}} key={i}>
                                   <Text style={{padding:2, width:150}}>Query: {element.search_query}</Text>
                                   <Text style={{padding:2, width:150}}>Type: {element.search_type}</Text>
                                   <Text style={{padding:2, width:250}}>Time: {moment(element.timestamp.timestamp).format()}</Text> 
                            </View>
                        );                       
                    })
                    }
                </View>
                </PersonalInformationSubHeader>
                </PersonalInformationHeader>
            </>)})}
      </React.Fragment>
    );
}