import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, Alert } from 'react-native';
import { BaseStyle } from '../stylesheets/BaseStyle';
import { Color } from '../utils/color';
import Label from './Label';
import { DataTable } from 'react-native-paper';
import { PersonalInformationHeader } from './PersonalInformationHeader';
import { PersonalInformationSubHeader } from './PersonalInformationSubHeader';
import { SubHeader } from './SubHeader';
import { EvilIcons } from '@expo/vector-icons';

const fetchSocialShare = async (props) => {
      let response = await fetch('https://mycxapi.cxsphere.com/user/data?at=' + props.accessToken, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + props.accessToken
        },
    });
    let responseJson = await response.json();
    const leadgen = [];
    const ecom = [];
    for(let i=0;i<responseJson.length;i++) {
            if(responseJson[i].source !== 'facebook' && responseJson[i].source !== 'google') {
                if(responseJson[i].source === 'leadgen' && responseJson[i].transactions) {
                    leadgen.push(responseJson[i]);
                } else if(responseJson[i].transactions) {
                    ecom.push(responseJson[i])
                }
            }
    }

    return { leadgen, ecom };
}


export const CommercePlatform = (props) => {
    const [leadgen, setLeadgen] = useState([]);
    const [ecom, setEcom] = useState([]);
    useEffect( () => {
            fetchSocialShare(props)
                .then(arg => {
                    setEcom(arg.ecom);
                    setLeadgen(arg.leadgen);
                })
    },[]);

    const _addToRow = (element, key) => {
        const rows = [];
        for(let x in element) {
              rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}}> 
              <Text style={{
                  fontFamily: 'Barlow-Regular', fontSize:12
              }}>{ element[x] } </Text> </DataTable.Cell>);
        }      
        return rows;
    }

    return (
        <React.Fragment>
        <PersonalInformationHeader title='Leadgen'>
            {leadgen && leadgen.map((identity, loopIndex) => {
            return (
                <PersonalInformationSubHeader title={identity.account_name}>
                        <DataTable.Header style={{ backgroundColor:'#eee'}}>
                         { Object.keys(identity.transactions[0]).map((item, key) => {
                            return ( <DataTable.Title style={{ justifyContent:'space-evenly'}}>
                                        <Text style={{
                                        fontFamily: 'Barlow-Regular', 
                                        color: Color.DarkGreen, 
                                        fontWeight:'600'}} >{item} </Text>
                                        </DataTable.Title>)     
                         })} 
                        </DataTable.Header>
                        {identity.transactions.map((element) => 
                            <DataTable.Row style={{backgroundColor:'#fff'}}>
                            {
                                _addToRow(element)
                            }
                            </DataTable.Row>   
                        )}
                </PersonalInformationSubHeader>        
            )})}
      </PersonalInformationHeader>
      <PersonalInformationHeader title='Commerce'>
            {ecom && ecom.map((identity, loopIndex) => {
            return (
                <PersonalInformationSubHeader title={identity.store_name}>
                        <DataTable.Header style={{ backgroundColor:'#eee'}}>
                         { Object.keys(identity.transactions[0]).map((item, key) => {
                            return ( <DataTable.Title style={{ justifyContent:'space-evenly'}}>
                                        <Text style={{
                                        fontFamily: 'Barlow-Regular', 
                                        color: Color.DarkGreen, 
                                        fontWeight:'600'}} >{item} </Text>
                                        </DataTable.Title>)     
                         })} 
                        </DataTable.Header>
                        {identity.transactions.map((element) => 
                            <DataTable.Row style={{backgroundColor:'#fff'}}>
                            {
                                _addToRow(element)
                            }
                            </DataTable.Row>   
                        )}
                </PersonalInformationSubHeader>        
            )})}
      </PersonalInformationHeader>
      </React.Fragment>
    );
}