import Constants from "expo-constants";
import {API_URL, PROTOCOL} from 'react-native-dotenv';

const { manifest } = Constants;
let api = API_URL
api = api || ((typeof manifest.packagerOpts === `object`) && manifest.packagerOpts.dev
  ? manifest.debuggerHost.split(`:`).shift().concat(`:5000`)
  : `localhost:5000`);
const config = {
  'apiurl': PROTOCOL + api
};

export default config;