import { createAppContainer,createSwitchNavigator, createStackNavigator } from 'react-navigation';
import { Platform } from 'react-native';
import config from './config';
import LoadingNavigation from './LoadingNavigation';
import LoginNavigation from './LoginNavigation';
import SettingsScreen from '../screens/SettingsScreen';
import {AppBottomNavigator, AppWebNavigator} from './AppNavigation';
const AuthStack = createSwitchNavigator(
    {
        AuthLoadingScreen: LoadingNavigation,
        Auth: LoginNavigation,
        Settings:SettingsScreen,
        App: Platform.select({
            web: AppWebNavigator,
            default: AppBottomNavigator
        })
    },
    {
        initialRouteName: 'AuthLoadingScreen'
    },
    config
);

AuthStack.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

AuthStack.path = '';

export default AuthStack;