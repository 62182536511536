import React, { useEffect, useState, useContext } from 'react';
import { BaseStyle } from '../stylesheets/BaseStyle';
import {  Text, View, StyleSheet, TouchableOpacity , Alert, ScrollView, Dimensions, Platform  } from 'react-native';
import { Modal, TouchableHighlight } from 'react-native';
import AuthenticatedLayout from './AuthenticatedLayout';
import { DataTable, Card } from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import { Image, Button, Icon } from 'react-native-elements';
import { Color } from '../utils/color';
import config from '../config';
import { UserContext } from '../UserContext';
import {fetchProduct, addToCart } from '../services/ProductService';
import {fetchLinkedSocial} from '../services/ProfileService';
import {Feather} from '@expo/vector-icons';
import {showConfirm, showMessage } from '../utils/UIUtil';
import TextBox from '../components/textbox';

import MobileProductContent from '../components/mobile/Product'
import WebProductContent from '../components/web/Product'
import ProductHomeScreen from './ProductHomeScreen';
export default function ProductScreen(props) {
    const userData = useContext(UserContext);
    if(!userData.userData.user_id) {
        return <ProductHomeScreen {...props} />
    }
    return Platform.OS === 'web' ? (
        <AuthenticatedLayout {...props} title="Product">
            {originalWidth => (<WebProductContent {...userData.userData} {...props} />)}
        </AuthenticatedLayout>
    ) :     <AuthenticatedLayout {...props} title="Product">
              {originalWidth => (<MobileProductContent {...userData.userData} {...props} />)}
            </AuthenticatedLayout>
    

}