import {createStackNavigator} from 'react-navigation';
import config from './config';
import LoginScreen from '../screens/LoginScreen';
import RegistrationScreen from '../screens/RegistrationScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import GoogleLoginCallbackScreen from '../screens/GoogleLoginCallbackScreen';
const LoginNavigation = createStackNavigator(
    {
        Login: LoginScreen,
        Registration: RegistrationScreen,
        ForgotPassword: ForgotPasswordScreen,
        ResetPassword: ResetPasswordScreen,
        LoginCallback: GoogleLoginCallbackScreen
    },
    config
);

LoginNavigation.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

LoginNavigation.path = '';
export default LoginNavigation;